import React from 'react'

import { ContainerStyled } from './styled'
import { IContainer } from '../../../types'

const Container: React.FC<IContainer> = (params: IContainer) => {
    return (
        <ContainerStyled
            ref={params.ref}
            className={params.className}
            fluid={params.fluid}
            inline={params.inline}
            flex={params.flex}
            flexGrow={params.flexGrow}

            height={params.height}
            minHeight={params.minHeight}
            maxHeight={params.maxHeight}
            width={params.width}
            minWidth={params.minWidth}
            maxWidth={params.maxWidth}
            color={params.color}

            flexStart={params.flexStart}
            flexEnd={params.flexEnd}
            centered={params.centered}
            contentCentered={params.contentCentered}
            horizontalCentered={params.horizontalCentered}
            verticalCentered={params.verticalCentered}

            border={params.border}
            borderRadius={params.borderRadius}
            borderTopRadius={params.borderTopRadius}
            borderBottomRadius={params.borderBottomRadius}

            horizontalPadded={params.horizontalPadded}
            verticalPadded={params.verticalPadded}
            padded={params.padded}
            veryHorizontalPadded={params.veryHorizontalPadded}
            veryVerticalPadded={params.veryVerticalPadded}
            veryPadded={params.veryPadded}

            spacedAround={params.spacedAround}
            spacedBetween={params.spacedBetween}
            spacedEvenly={params.spacedEvenly}

            hasShadow={params.hasShadow}

            style={(params.style && { ...params.style }) || (params.sx && { ...params.sx })}
            onClick={() => params.onClick && params.onClick()}

            relative={params.relative}
            absolute={params.absolute}
        >
            {params.children}
        </ContainerStyled >
    )
}

export default Container