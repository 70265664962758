import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Divider, Grid, IconButton, SelectChangeEvent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@mui/icons-material";
import moment from "moment";

import backgroundImage from "../../../assets/fundo.png";
import { Container, Input, Page, Spacing, Text, theme, } from "../../../components";
import { useInformation } from "../../../hooks/information";
import { useManagements } from "../../../hooks/managements";
import { IManagement, ISelectValue } from "../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';

export const repeatTypes: ISelectValue[] = [
    { value: '0', label: 'Meses' },
    { value: '1', label: 'Dias' }
];

const ManagementsRegister: FC = () => {
    const labelColor: string = theme.palette.grey[600];

    const navigate = useNavigate();
    const { id } = useParams();
    const isEdit = id != undefined;

    const { hideInformation, showInformation } = useInformation();
    const { createNewManagement, editManagement, fetchManagement } = useManagements();

    const [editItem, setEditItem] = useState<IManagement | undefined>(undefined);
    const [code, setCode] = useState<number>(0);
    const [codeError, setCodeError] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchData(id);
    }, []);

    useEffect(() => {
        if (!editItem) return;

        setCode(editItem.code);
        setName(editItem.name);
    }, [editItem]);

    const fetchData = async (_id: string | undefined) => {
        if (isEdit && _id) {
            let auxManagement = await fetchManagement(_id);
            setEditItem(auxManagement);
        }
    }

    const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(parseInt(event.target.value))) {
            setCode(parseInt(event.target.value));
        }

        setCodeError(undefined);
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const isValid = () => {
        let valid: boolean = true;

        if (name == '') {
            setNameError('Favor informar o nome');
            valid = false;
        }

        if (valid) {
            setCodeError(undefined);
            setNameError(undefined);
            setError(undefined);
        }

        return valid;
    }

    const save = async () => {
        if (!isValid()) return;

        try {
            setLoading(true);

            let payload: IManagement = {
                code,
                name
            }

            let successMessage: string = '';
            if (isEdit && editItem && editItem.id) {
                payload.id = editItem.id;

                await editManagement(editItem.id, payload);
                successMessage = 'Gerência editado com sucesso.'
            } else {
                await createNewManagement(payload);
                successMessage = 'Gerência criado com sucesso.'
            }

            setLoading(false);
            showInformation(successMessage, undefined, undefined, () => {
                navigate('/managements');

                cleanData();
                hideInformation();
            });
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            }
            setLoading(false);
        }
    }

    const cleanData = () => {
        setEditItem(undefined);
        setCode(0);
        setCodeError(undefined);
        setName('');
        setNameError(undefined);
        setError(undefined);
    }


    return (
        <Page
            style={{
                backgroundImage: `url(${backgroundImage})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <Container fluid centered width="100%" height="100vh">
                <Container
                    fluid
                    hasShadow
                    width="100%"
                    height="100%"
                    color={theme.palette.background.paper}
                    borderRadius={BORDER_RADIUS}
                    horizontalCentered
                    sx={{
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                        marginTop: "120px",
                    }}>
                    <Spacing top={PADDING} />
                    <Container fluid padded width="1080px" hasShadow color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}>
                        <Container inline horizontalCentered>
                            <Link to="/managements">
                                <IconButton color="primary" size="small">
                                    <KeyboardArrowLeftIcon fontSize="large" />
                                </IconButton>
                            </Link>
                            <Spacing left={PADDING} />
                            <Text bold size={22} color={theme.palette.primary.main}>Gerências - {!isEdit ? 'Novo' : 'Editar'}</Text>
                        </Container>
                        <Spacing top={PADDING} />
                        <Grid container columnSpacing={2} alignItems="center" sx={{ paddingRight: 2 }}>
                            <Grid item xs={4}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*Código:</Text>
                                    <Input
                                        variant="outlined"
                                        type="number"
                                        value={code}
                                        onChange={handleChangeCode}
                                        disabled={loading}
                                        placeholder='Informe o código' />
                                    {codeError && <Text size={13} color={theme.palette.error.main} >{codeError}</Text>}
                                </Container>
                            </Grid>
                            <Grid item xs={8}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*Nome:</Text>
                                    <Input
                                        variant="outlined"
                                        type="text"
                                        value={name}
                                        onChange={handleChangeName}
                                        disabled={loading}
                                        placeholder='Informe o nome' />
                                    {nameError && <Text size={13} color={theme.palette.error.main} >{nameError}</Text>}
                                </Container>
                            </Grid>
                        </Grid>

                        <Container fluid flex padded>
                            {error && <Text size={13} color={theme.palette.error.main} >{error}</Text>}
                            <Spacing top={VERY_PADDING} />
                            <LoadingButton
                                variant="contained"
                                size="small"
                                loading={loading}
                                disabled={loading}
                                style={{ backgroundColor: loading ? theme.palette.grayLight.light : theme.palette.info.main, width: 150, height: 35 }}
                                onClick={() => save()}>
                                <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                            </LoadingButton>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page>
    );
}

export default ManagementsRegister;