import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { convertApiResponseToICompany } from "./companies";

import {
    ICompany,
    IAnalysisAnnotations,
    IAnalysisAnnotationsFilterParams,
} from "../types";

const baseUrl: string = "/v1_0/carcasses/analysis_annotations";

export const fetchAnalysisAnnotations = async (
    _params: IAnalysisAnnotationsFilterParams,
) => {
    let filter = "";
    if (_params.initialDate)
        filter += `?initial_date=${moment(_params.initialDate).format(
            "YYYY-MM-DD",
        )}`;
    if (_params.finalDate)
        filter +=
            (filter != "" ? "&" : "?") +
            `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    if (_params.companies.length > 0)
        filter +=
            (filter != "" ? "&" : "?") +
            `companies_id=${_params.companies
                .map((item) => `${item.id}`)
                .join(",")}`;

    const response: any = await api.get(baseUrl + filter);
    const analysisAnnotations: IAnalysisAnnotations[] = [];

    if (response && response.request) {
        const status = response.request.status;
        if (status == 200) {
            const data = response.data;

            if (data)
                for (let i = 0; i < data.length; i++) {
                    analysisAnnotations.push(
                        convertApiResponseToAnalysisAnnotations(data[i]),
                    );
                }
        }
    }

    return analysisAnnotations;
};

export const fetchAnalysisAnnotationsGroupSides = async (
    _params: IAnalysisAnnotationsFilterParams,
) => {
    let filter = "";
    if (_params.initialDate)
        filter += `?initial_date=${moment(_params.initialDate).format(
            "YYYY-MM-DD",
        )}`;
    if (_params.finalDate)
        filter +=
            (filter != "" ? "&" : "?") +
            `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    if (_params.companies.length > 0)
        filter +=
            (filter != "" ? "&" : "?") +
            `companies_id=${_params.companies
                .map((item) => `${item.id}`)
                .join(",")}`;

    const response: any = await api.get(`${baseUrl}/group_sides${filter}`);
    const analysisAnnotations: IAnalysisAnnotations[] = [];

    if (response && response.request) {
        const status = response.request.status;
        if (status == 200) {
            const data = response.data;

            if (data)
                for (let i = 0; i < data.length; i++) {
                    let dataConvert = convertApiResponseToAnalysisAnnotations(
                        data[i],
                    );
                    analysisAnnotations.push(dataConvert);
                    console.log(i, analysisAnnotations[i]);
                }
        }
    }

    return analysisAnnotations;
};

export const convertApiResponseToAnalysisAnnotations = (
    _analysis_annotations: any,
) => {
    let company: ICompany | undefined = undefined;
    if (_analysis_annotations.company)
        company = convertApiResponseToICompany(_analysis_annotations.company);
    else {
        company = {
            id: _analysis_annotations.company_id,
            name: "",
            fridge: "",
            token: "",
            prefix: "",
        };

        if (_analysis_annotations.company_name)
            company.name = _analysis_annotations.company_name;
        if (_analysis_annotations.companyFridge)
            company.fridge = _analysis_annotations.companyFridge;
    }

    const analysisAnnotations: IAnalysisAnnotations = {
        company: company,
        classificationDate: _analysis_annotations.classification_date,
        countCarcass: _analysis_annotations.count_carcasses,
        countAnnotations: _analysis_annotations.count_annotations,
        countRevisions: _analysis_annotations.count_revisions,
    };

    return analysisAnnotations;
};
