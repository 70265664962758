import { AxiosResponse } from "axios";

import api from ".";

export const createPrivilegeHasRoute = async (
  _privilegeId: string,
  _routeId: string
) => {
  const response: AxiosResponse = await api.post(
    "/v1_0/privileges_has_routes",
    {
      privilegeId: _privilegeId,
      routeId: _routeId,
    }
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const id = response.data.id;

      return id;
    }
  }

  throw new Error("Não foi possível criar a vinculação de privilégio com rota");
};

export const deleteAllPrivilegeHasRouteByPrivilegeId = async (
  _privilegeId: string
) => {
  const response: AxiosResponse = await api.delete(
    `/v1_0/privileges_has_routes/by_privilege_id/${_privilegeId}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return true;
    }
  }

  throw new Error(
    "Não foi possível excluir a vinculação de privilégio com rota de todos os privilégios"
  );
};
