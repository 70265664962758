import React, { FC } from "react";

import Container from "../../atoms/container";
import Modal from "../../atoms/modal";
import Spacing from "../../atoms/spacing";
import Text from "../../atoms/text";
import Theme from "../../theme";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { ILoading } from "../../../types/components/molecules/modals";
import { BORDER_RADIUS } from "../../../utils/consts";

const Loading: FC<ILoading> = (params: ILoading) => {
    return (
        <Modal isOpened={params.open}>
            <Container
                fluid
                color={Theme.palette.background.default}
                hasShadow
                centered
                borderRadius={10}
            >
                {params.title && (
                    <Container
                        inline
                        color={Theme.palette.grayLight.main}
                        centered
                        width="100%"
                        borderTopRadius={BORDER_RADIUS}
                    >
                        <Container padded>
                            <Text
                                size={16}
                                bold
                                color={Theme.palette.primary.light}
                            >
                                {params.title}
                            </Text>
                        </Container>
                    </Container>
                )}

                <Container fluid spacedAround veryPadded centered>
                    <Spacing top={10} />
                    {params.message && (
                        <Text bold center>
                            {params.message}
                        </Text>
                    )}
                    {params.confirmMessage && (
                        <>
                            <Spacing top={20} />
                            <Text bold center>
                                {params.confirmMessage}
                            </Text>
                        </>
                    )}
                    <Spacing top={20} />

                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Container>
            </Container>
        </Modal>
    );
};

export default Loading;
