import React from "react";
import { Routes, Route } from "react-router-dom";

import { Login } from "../pages";

const AuthRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
        </Routes>
    );
};

export default AuthRoutes;
