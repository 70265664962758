import axios, { AxiosResponse } from "axios";

export const API_URL =
((window as any)._env_ && (window as any)._env_.REACT_APP_API_URL) ||
  process.env.REACT_APP_API_URL ||
  "http://localhost:5555/";

const api = axios.create();
api.defaults.baseURL = API_URL;

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    let statusCode = error?.response?.status || 401;
    let statusText = error?.response?.data || "Sua sessão expirou";
    // alert("Tivemos um problema ao excutar a ação, tente novamente")
    // if (statusCode == 401) {
    //   if (window.location.pathname !== "/") {
    //     window.location.href = "/";
    //   }
    //   return Promise.resolve(error);
    // }
    // console.log(error)
    // return Promise.resolve(error);
    return Promise.resolve(error);
  }
);

export default api;
