import api from "./";
import {
  IDashClassificationControl,
  IDashClassificationQuality,
  IDashDentitionControl,
  IDashSexControl,
  IDashWeightRangeControl,
  IDashboardBarChartData,
} from "../types";

const baseUrl: string = "/v1_0/dashboard";

export const getCarcassThisWeekCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/carcasses_count_this_week${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};

export const getCarcassThisMonthCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/carcasses_count_this_month${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};

export const getCarcassAllTimeCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/carcasses_count_all_time${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};

export const getCompaniesCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/companies_count${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};

export const getUsersCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/users_count${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};

export const getCarcassesCountByClassification = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_classifications_count${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountByDentition = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_dentition_count${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountByWeight = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_weight_count${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountBySex = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_sex_count${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountAnnotationsXRevisions = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_count_x_annotations_and_revisions${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountPerClassificationsAnnotationsXRevisions = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcass_count_per_classification_annotations_x_revisions${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};


export const convertApiResponseToIDashboardBarChart = (_data: any) => {
  const data: IDashboardBarChartData = {
    name: _data.name,
    value: _data.count,
    type: _data.type
  };
  return data;
};

export const fetchClassificationControl = async () => {
  const response: any = await api.get("/dashboard/classification_control");

  const classificationControlData: IDashClassificationControl[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data)
        for (let i = 0; i < data.length; i++) {
          classificationControlData.push({
            name: data[i].name,
            value: data[i].quantidade,
          });
        }
    }
    return classificationControlData;
  }
};

export const fetchClassificationQuality = async () => {
  const response: any = await api.get("/dashboard/classification_quality");

  const classificationQualityData: IDashClassificationQuality[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data)
        for (let i = 0; i < data.length; i++) {
          classificationQualityData.push({
            name: data[i].name,
            Quantidade: data[i].quantidade,
          });
        }
    }
    return classificationQualityData;
  }
};

export const fetchDentitionControl = async () => {
  const response: any = await api.get("/dashboard/dentition_control");

  const dentitionControlData: IDashDentitionControl[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data)
        for (let i = 0; i < data.length; i++) {
          dentitionControlData.push({
            name: data[i].name,
            Quantidade: data[i].quantidade,
          });
        }
    }
    return dentitionControlData;
  }
};

export const fetchSexControl = async () => {
  const response: any = await api.get("/dashboard/sex_control");

  const sexControlData: IDashSexControl[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data)
        for (let i = 0; i < data.length; i++) {
          sexControlData.push({
            name: data[i].name,
            Quantidade: data[i].quantidade,
          });
        }
    }
    return sexControlData;
  }
};

export const fetchWeightRangeControl = async () => {
  const response: any = await api.get("/dashboard/weight_range");

  const weightRangeData: IDashWeightRangeControl[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data)
        for (let i = 0; i < data.length; i++) {
          weightRangeData.push({
            name: data[i].name,
            Quantidade: data[i].quantidade,
          });
        }
    }
    return weightRangeData;
  }
};

export const getAnnotationsThisWeekCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/annotations_count_this_week${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};

export const getRevisionsThisWeekCount = async (_query: string) => {
  const response: any = await api.get(`${baseUrl}/revisions_count_this_week${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data.count || 0;
      return count;
    }
  }
  return 0;
};