import { AxiosResponse } from "axios";

import api from ".";
import { IManagement, IUnit } from "../types";
import { convertApiResponseToIManagement } from "./managements";

const baseUrl: string = "units";

export const createUnit = async (_unit: IUnit) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    managementId: _unit.managementId,
    code: _unit.code,
    name: _unit.name,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIUnit(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if ((response as any).response.data.error)
        throw new Error((response as any).response.data.error);
      else throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateUnit = async (_id: string, _unit: IUnit) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    managementId: _unit.managementId,
    code: _unit.code,
    name: _unit.name,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIUnit(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if ((response as any).response.data.error)
        throw new Error((response as any).response.data.error);
      else throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyUnit = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getUnits = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const units: IUnit[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const unit: IUnit = convertApiResponseToIUnit(data);

        units.push(unit);
      }

      return units;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getUnit = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const unit: IUnit = convertApiResponseToIUnit(response.data);
      return unit;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIUnit = (_unit: any) => {
  let management: IManagement | undefined = undefined;
  if (_unit.management)
    management = convertApiResponseToIManagement(_unit.management);
  const unit: IUnit = {
    id: _unit.id,
    managementId: _unit.managementId,
    management: management,
    code: _unit.code,
    name: _unit.name,
    managementName: management?.name,
    isDeleted: _unit.isDeleted,
    createdAt: _unit.createdAt,
    updatedAt: _unit.updatedAt,
  };

  return unit;
};
