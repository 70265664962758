import { AxiosResponse } from "axios";

import api from ".";
import { IPrivilege, IRoute } from "../types";

const baseUrl: string = "privileges";

export const createPrivilege = async (_payload: IPrivilege) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, _payload);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const privilege: IPrivilege = convertApiResponseToIPrivilege(
        response.data
      );
      return privilege;
    }
  }

  throw new Error("Não foi possível criar um novo privilégio");
};

export const updatePrivilege = async (
  _privilegeId: string,
  _payload: IPrivilege
) => {
  const response: AxiosResponse = await api.put(
    `/v1_0/${baseUrl}/${_privilegeId}`,
    _payload
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const privilege: IPrivilege = convertApiResponseToIPrivilege(
        response.data
      );
      return privilege;
    }
  }

  throw new Error("Não foi possível editar o privilégio");
};

export const deletePrivilege = async (_privilegeId: string) => {
  const response: AxiosResponse = await api.delete(
    `/v1_0/${baseUrl}/${_privilegeId}`
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const privilege: IPrivilege = convertApiResponseToIPrivilege(
        response.data
      );
      return privilege;
    }
  }

  throw new Error("Não foi possível excluir o privilégio");
};

export const getPrivileges = async (_isSuperAdmin: boolean) => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const privileges: IPrivilege[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        if (_isSuperAdmin == false) {
          if (data.description.indexOf("SUPER_ADMIN") >= 0) continue;
        }

        const routes: IRoute[] = [];
        if (data.routes) {
          for (let j = 0; j < data.routes.length; j++) {
            const route = data.routes[j];
            routes.push({
              id: route.id,
              path: route.path,
            });
          }
        }

        const privilege: IPrivilege = {
          id: data.id,
          name: data.name,
          description: data.description,
          routes,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };

        privileges.push(privilege);
      }

      return privileges;
    }
  }

  throw new Error("Não foi possível buscar os privilégios");
};

export const getPrivilege = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const privilege: IPrivilege = convertApiResponseToIPrivilege(response.data);
      return privilege;
    }
  }

  throw new Error("Não foi possível buscar o privilégio");
};

export const getPrivilegeByUserId = async (_userId: string) => {
  const response: AxiosResponse = await api.get(
    `/v1_0/privileges/by_user_id/${_userId}`
  );

  if (response && response.request) {
    const status = response.request.status;

    const routes: IRoute[] = [];
    if (response.data.routes) {
      for (let i = 0; i < response.data.routes.length; i++) {
        const route = response.data.routes[i];
        routes.push({
          id: route.id,
          path: route.path,
        });
      }
    }

    const privilege: IPrivilege = convertApiResponseToIPrivilege(response.data);
    return privilege;
  }

  throw new Error("Não foi possível encontrar o privilégio do usuário");
};

export const convertApiResponseToIPrivilege = (_data: any) => {
  const routes: IRoute[] = [];
  if (_data.routes) {
    for (let j = 0; j < _data.routes.length; j++) {
      const route = _data.routes[j];
      routes.push({
        id: route.id,
        path: route.path,
      });
    }
  }

  const privilege: IPrivilege = {
    id: _data.id,
    name: _data.name,
    description: _data.description,
    routes,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return privilege;
};
