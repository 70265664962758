import React, { FC, useEffect } from 'react'
import { Autocomplete as MuiAutoComplete, TextField } from '@mui/material'

import Text from '../../text';
import theme from '../../../theme';
import { IAutocompleteNew } from '../../../../types';

interface OptionType {
    label: string;
    id: string;
    isOptionEqualToValue?: boolean;
}

const AutocompleteNew: FC<IAutocompleteNew> = (params: IAutocompleteNew) => {
    const options: OptionType[] = params.options.map((_item: any) => {
        let label: string = 'Desconhecido';
        let id: string = '';
        if (_item.id) id = _item.id;

        if (_item.name) label = _item.name;
        else if (_item.description) label = _item.description;
        else if (_item.profile && _item.profile.surname) label = _item.profile.surname;
        else if (_item.fullName) label = _item.fullName;
        else if (_item.path) label = _item.path;
        
        if (_item.managementName) label = `${label} - ${_item.managementName}`;
        if (_item.unitName) label = label + _item.unitName;

        const option: OptionType = {
            label: label,
            id: id,
            isOptionEqualToValue: false
        };
        return option;
    });

    const onChangeValue = (event: any, newValue: any) => {
        params.setValue(newValue);
    }

    const onChangeInputValue = (event: any, newInputValue: string) => {
        if (event == null && params.value && params.value.label)
            params.setInputValue(params.value.label);
        else if (event == null && params.value == undefined)
            params.setInputValue('');
        else
            params.setInputValue(newInputValue);
    }

    return (
        <>
            <MuiAutoComplete
                id={params.id}
                disablePortal
                value={params.value}
                onChange={onChangeValue}
                inputValue={params.inputValue}
                onInputChange={onChangeInputValue}
                freeSolo={!params.requiredExists}
                placeholder={params.placeHolder}
                options={options}
                disabled={params.disabled}
                renderInput={(_params: any) => (
                    <TextField
                        {..._params}
                        id={params.id}
                        autoFocus={params.autoFocus}
                        required={params.required}
                        label={params.label}
                        placeholder={params.placeHolder}
                        error={params.error}
                        disabled={params.disabled}
                        variant={params.variant || "standard"}
                        size='small'
                        style={{
                            minHeight: params.multiline ? '50px' : 'auto',
                            ...params.sx,
                        }}
                    />
                )}
            />
            {params.error && <Text size={13} color={theme.palette.error.main} >{params.error}</Text>}
        </>
    )
}

export default AutocompleteNew;