import React, { FC } from "react";

import { AnalysisAnnotationsProvider } from "./analysisAnnotations";
import { FormsProvider } from "./forms";

import { ConfirmationProvider } from "./confirmation";
import { CompaniesProvider } from "./companies";
import { CompaniesHasUserProvider } from "./companiesHasUser";
import { DashboardProvider } from "./dashboard";
import { DrawerProvider } from "./drawer";
import { InformationProvider } from "./information";
import { ManagementsProvider } from "./managements";
import { PrivilegesProvider } from "./privileges";

import { RoutesProvider } from "./routes";
import { UnitsProvider } from "./units";
import { UsersProvider } from "./users";
import { VerdictsProvider } from "./verdicts";

const RegistrationProvider: FC<any> = ({ children }) => {
    return (
        <FormsProvider>
            <ManagementsProvider>
                <UnitsProvider>
                    <AnalysisAnnotationsProvider>
                        <CompaniesProvider>
                            <CompaniesHasUserProvider>
                                <PrivilegesProvider>
                                    <RoutesProvider>
                                        <UsersProvider>{children}</UsersProvider>
                                    </RoutesProvider>
                                </PrivilegesProvider>
                            </CompaniesHasUserProvider>
                        </CompaniesProvider>
                    </AnalysisAnnotationsProvider>
                </UnitsProvider>
            </ManagementsProvider>
        </FormsProvider>
    );
};

const HooksProvider: FC<any> = ({ children }) => {
    return (
        <DrawerProvider>
            <DashboardProvider>
                <RegistrationProvider>{children}</RegistrationProvider>
            </DashboardProvider>
        </DrawerProvider>
    );
};

export const FeedbackProvider: FC<any> = ({ children }) => {
    return (
        <InformationProvider>
            <ConfirmationProvider>{children}</ConfirmationProvider>
        </InformationProvider>
    );
};

export default HooksProvider;
