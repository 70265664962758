import React, { FC, createContext, useContext, useState } from 'react'
import {
    createRoute, getRoutes, getRoute, updateRoute,
    deleteRoute as deleteRouteService
} from '../services/routes';

import { IRoute, IRouteContext } from '../types'

const RoutesContext = createContext<IRouteContext>({} as IRouteContext);

export const RoutesProvider: FC<any> = ({ children }) => {
    const [routes, setRoutes] = useState<IRoute[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchRoute, setSearchRoute] = useState<string>('');
    const [routesPerRow, setRoutesPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchRoutes = async () => {
        setLoading(true);
        try {
            const routes: IRoute[] = await getRoutes();
            setRoutes([...routes]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    const fetchRoute = async (_id: string) => {
        const route: IRoute = await getRoute(_id);
        return route;
    }

    const createNewRoute = async (_payload: IRoute) => {
        const privilege = await createRoute(_payload);
        return privilege;
    }

    const editRoute = async (_privilegeId: string, _payload: IRoute) => {
        const privilege = await updateRoute(_privilegeId, _payload);
        return privilege;
    }

    const deleteRoute = async (_privilegeId: string) => {
        await deleteRouteService(_privilegeId);
    }

    return (
        <RoutesContext.Provider value={{
            routes, loading,
            fetchRoutes, fetchRoute, createNewRoute, editRoute, deleteRoute,
            searchRoute, setSearchRoute, routesPerRow, setRoutesPerRow, paginate, setPaginate
        }}>{children}</RoutesContext.Provider>
    )
}

export function useRoutes() {
    const context = useContext(RoutesContext);

    if (!context) {
        throw new Error('useRoutes must be used within an RoutesProvider')
    }

    return context;
}