import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { convertApiResponseToICompany } from "./companies";

import {
    ICompany,
    IForms,
    IFormsFilterParams,
} from "../types";

const baseUrl: string = "/v1_0/forms";

export const fetchForms = async (
    _params: IFormsFilterParams,
) => {
    let filter = "";
    if (_params.initialDate)
        filter += `/${moment(_params.initialDate).format(
            "YYYY-MM-DD",
        )}`;
    if (_params.finalDate)
        filter +=

            `/${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    if (_params.unitId)
        filter +=
            (filter != "" ? "&" : "?") +
                `unitId=${_params.unitId}`;


    const response: any = await api.post(baseUrl + "/upload");
    // const response: any = await api.get(baseUrl + filter);
    // const analysisAnnotations: IAnalysisAnnotations[] = [];

    if (response && response.request) {
        const status = response.request.status;
        if (status == 200) {
            const data = response.data;

            if (data){
                console.log(data)
            }
                // for (let i = 0; i < data.length; i++) {
                //     analysisAnnotations.push(
                //         convertApiResponseToAnalysisAnnotations(data[i]),
                //     );
                // }
        }
    }

    return [];
    // return analysisAnnotations;
};

export const uploadFormsInference = async (
    _params: any,
) => {

    console.log(_params)

    const response: any = await api.post(`${baseUrl}/upload`, _params);
    console.log(response)

};

export const fetchFormsGroupSides = async (
    _params: IFormsFilterParams,
) => {
    let filter = "";
    if (_params.initialDate)
        filter += `/${moment(_params.initialDate).format(
            "YYYY-MM-DD",
        )}`;
    if (_params.finalDate)
        filter += `/${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    // if (_params.unitId)
    //     filter += `/${_params.unitId}`;

    const response: any = await api.get(`${baseUrl}`+ filter);
    var forms: any[] = [];

    if (response && response.request) {
        const status = response.request.status;
        if (status == 200) {

            forms = response.data;
            // if (data)
            //     for (let i = 0; i < data.length; i++) {
            //         let dataConvert = convertApiResponseToAnalysisAnnotations(
            //             data[i],
            //         );
            //         forms.push(dataConvert);
            //         console.log(i, analysisAnnotations[i]);
            //     }
        }
    }

    return forms;
};

export const convertApiResponseToForms = (
    _analysis_annotations: any,
) => {
    let company: ICompany | undefined = undefined;
    if (_analysis_annotations.company)
        company = convertApiResponseToICompany(_analysis_annotations.company);
    else {
        company = {
            id: _analysis_annotations.company_id,
            name: "",
            fridge: "",
            token: "",
            prefix: "",
        };

        if (_analysis_annotations.company_name)
            company.name = _analysis_annotations.company_name;
        if (_analysis_annotations.companyFridge)
            company.fridge = _analysis_annotations.companyFridge;
    }

    const forms: IForms = {
        company: company,
        classificationDate: _analysis_annotations.classification_date,
        countCarcass: _analysis_annotations.count_carcasses,
        countAnnotations: _analysis_annotations.count_annotations,
        countRevisions: _analysis_annotations.count_revisions,
    };

    return forms;
};
