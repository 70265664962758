import api from "./";
import { IUser } from "../types";
import { convertApiResponseToIUser } from "./users";

export const login = async (_email: string, _passw: string) => {
  const response: any = await api.post("/v1_0/auth/login", {
    email: _email,
    password: _passw,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const token = response.data.token;
      const user: IUser = convertApiResponseToIUser(response.data.user);
      return {
        token,
        user,
      };
    } else if (status == 401) {
      if (response.response.data.error == "Email or password invalid")
        throw new Error("Email ou senha inválidos");
      if (response.response.data.error == "Inactive user")
        throw new Error("Usuário inativado. Contate o seu administrador");
    }
  }

  throw new Error("Não foi possível efetuar o login");
};
