import React, { FC, createContext, useContext, useState } from 'react'
import {
    createPrivilege, getPrivileges, getPrivilege, updatePrivilege,
    deletePrivilege as deletePrivilegeService
} from '../services/privileges';
import { createPrivilegeHasRoute, deleteAllPrivilegeHasRouteByPrivilegeId } from '../services/privilegesHasRoutes';

import { IPrivilege, IPrivilegeContext } from '../types'

const PrivilegesContext = createContext<IPrivilegeContext>({} as IPrivilegeContext);

export const PrivilegesProvider: FC<any> = ({ children }) => {
    const [privileges, setPrivileges] = useState<IPrivilege[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchPrivilege, setSearchPrivilege] = useState<string>('');
    const [privilegesPerRow, setPrivilegesPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchPrivileges = async (_isSuperAdmin: boolean) => {
        setLoading(true);
        try {
            const privileges: IPrivilege[] = await getPrivileges(_isSuperAdmin);
            setPrivileges([...privileges]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    const fetchPrivilege = async (_id: string) => {
        const privilege: IPrivilege = await getPrivilege(_id);
        return privilege;
    }

    const createNewPrivilege = async (_payload: IPrivilege) => {
        const privilege = await createPrivilege(_payload);
        return privilege;
    }

    const editPrivilege = async (_privilegeId: string, _payload: IPrivilege) => {
        const privilege = await updatePrivilege(_privilegeId, _payload);
        return privilege;
    }

    const deletePrivilege = async (_privilegeId: string) => {
        await deletePrivilegeService(_privilegeId);
    }

    const createLinkWithRoute = async (_privilegeId: string, _routeId: string) => {
        const id: string = await createPrivilegeHasRoute(_privilegeId, _routeId);
        return id;
    };

    const destroyLinkWithAllRoutes = async (_privilegeId: string) => {
        await deleteAllPrivilegeHasRouteByPrivilegeId(_privilegeId);
    }

    return (
        <PrivilegesContext.Provider value={{
            privileges, loading,
            fetchPrivileges, fetchPrivilege, createNewPrivilege, editPrivilege, deletePrivilege,
            searchPrivilege, setSearchPrivilege, privilegesPerRow, setPrivilegesPerRow, paginate, setPaginate,
            createLinkWithRoute, destroyLinkWithAllRoutes
        }}>{children}</PrivilegesContext.Provider>
    )
}

export function usePrivileges() {
    const context = useContext(PrivilegesContext);

    if (!context) {
        throw new Error('usePrivileges must be used within an PrivilegesProvider')
    }

    return context;
}