import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fab, Grow } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/auth";
import { useUsers } from "../../hooks/users";

import { login as loginService } from "../../services/auth";
import Text from "../../components/atoms/text";
import Button from '@mui/material/Button';
import { handleDownload } from "../../utils/aws";
import { IUser, IListHeader, IFormsFilterParams } from "../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../utils/consts";
import { colors } from "../../components/theme/styles";
import { Grid, Box, IconButton } from "@mui/material";
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from "../../hooks/information";
import Stack from "@mui/material/Stack";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DateRangePicker from "../../components/atoms/dateRangePicker";

import backgroundImage from "../../assets/fundo.png";
import logo from "../../assets/pix_logo.png";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


import {
    Container,
    Image,
    Input,
    Page,
    Spacing,
    List,
    theme,
} from "../../components";

const Users: React.FC = () => {
    const navigate = useNavigate();
    const {
        loading,
        users,
        fetchUsers,
        deleteUser
    } = useUsers();

    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const { hasRights, user } = useAuth();
    const [search, setSearch] = useState<string>("");
    const [paginate, setPaginate] = useState<number>(0);

    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const statusFormatter = (params: any) => {
        var status = "Sucesso"
        var color = "green"
        var notDetected = params.value

        if (notDetected) {
            status = "Falha"
            color = "red"
        }


        return (
            <Text color={color} size={12}>
                {status}
            </Text>
        )
    };



    const dateFormatter = (params: any) => {
        const date = new Date(params.value)
        return date.toLocaleString('pt-BR', { hour12: false })
    };

    const cellRenderer = (params: any) => {

        var fileName = `${params.value}.jpg`
        var namePath = params.data.namePdf.replace(/\.[^/.]+$/, "")
        // return (<a href="https://www.google.com" target="_blank" rel="noopener">{params.value} /a>)
        return (
            <span>
                <a style={{ cursor: "pointer" }} onClick={() => handleDownload(namePath, fileName) as any} target="_blank" rel="noopener">{fileName} </a>
            </span>
        );
    }

    var filterParams = {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {

            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split(' ');
            var _dateParts = dateParts[0].split('-');

            var cellDate = new Date(
                Number(_dateParts[0]),
                Number(_dateParts[1]) - 1,
                Number(_dateParts[2])
            );

            var filterLocalDateAtMidnight = new Date(filterLocalDateAtMidnight)
            console.log(filterLocalDateAtMidnight, cellDate)
            if (filterLocalDateAtMidnight === cellDate) {
                return 1;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0
        },
        browserDatePicker: true,
        minValidYear: 2000,
        maxValidYear: 2025,
        inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
    };
    
    const [columnDefs] = useState([
        { field: 'name', headerName: "Nome", width: 350, resizable: true, },
        { field: 'managementName', headerName: "Gerência", width: 350, resizable: true, },
        { field: 'unitName', headerName: "Unidade", width: 350, resizable: true, },
        { field: 'createdAt', headerName: "Data de criação", valueFormatter: dateFormatter, resizable: true, filterParams: filterParams, },
        {
            field: 'actions', headerName: "Ações", cellRenderer: (item: any) => (
                <Container inline>
                     {(hasRights('/users@edit') || item.data.id === user?.id) && <IconButton color="primary" size="small" onClick={() => navigate(`/users/${item.data.id}`)}>
                        <EditIcon fontSize="small" />
                    </IconButton>}                     
                    {hasRights('/users@delete') && <IconButton color="error" size="small" onClick={() => onDelete(item.data.id)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>}
                </Container>
            )
        },
    ]);

    const onDelete = (id: any) => {
        showConfirmation('Deseja realmente excluir esse usuário?', undefined, undefined, undefined,
            async () => {
                await deleteUser(id);
                hideConfirmation();

                showInformation('Usuário excluído com sucesso.');
            })
    }


    return (
        <Page
            style={{
                backgroundImage: `url(${backgroundImage})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >

            <Container fluid centered height="100vh">
                <Container
                    hasShadow
                    width="100%"
                    height="100%"
                    color={theme.palette.background.paper}
                    borderRadius={BORDER_RADIUS}
                    sx={{
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                        marginTop: "120px",
                    }}>
                    <Container className="ag-theme-material" width="100%" height="100%" style={{ flex: '1 1 auto' }}>
                        <AgGridReact
                            rowData={users}
                            columnDefs={columnDefs}
                            debounceVerticalScrollbar
                            pagination={true}
                            overlayNoRowsTemplate={'<span>Sem dados</span>'}  
                            paginationAutoPageSize={true} />
                    </Container>
                </Container>

                {hasRights('/users@new') && <Grow in={!fabHover}>
                    <Fab
                        onClick={() => navigate('/users/register')}
                        variant="circular"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 50,
                            right: 16,
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        <AddIcon sx={{ color: theme.palette.background.paper }} />
                    </Fab>
                </Grow>}
                {hasRights('/users@new') && <Grow in={fabHover}>
                    <Fab
                        onClick={() => navigate('/users/register')}
                        variant="extended"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 50,
                            right: 16,
                            height: 56,
                            borderRadius: '28px',
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        {fabHover &&
                            <Container inline>
                                <Text size={14} color={theme.palette.background.paper}>Adicionar usuário</Text>
                                <Spacing left={PADDING} />
                            </Container>}
                        <AddIcon sx={{ color: theme.palette.background.paper }} />
                    </Fab>
                </Grow>}
            </Container>
        </Page>
    );
};

export default Users;
