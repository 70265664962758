import AWS from "aws-sdk";
import { BodyPart } from "aws-sdk/clients/rekognition";

const S3_BUCKET = "mrr008-ipiranga-lambda-classify-fields";
const REGION = "us-east-1";

AWS.config.update({
    accessKeyId: "AKIA52Y7RPYRSSQO3UHO",
    secretAccessKey: "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",
    maxRetries: 2,

    httpOptions: {

        timeout: 800000,

        connectTimeout: 5000

    }
});

const bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export const uploadFile = async (file: any, fileName:any, setProgress: any) => {
    
    const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileName,
        
    };

    try {
        const s3Response = await bucket
            .putObject(params)
            .on("httpUploadProgress", (evt: any) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .promise();
        
        return true 
    } catch (e) {
        return false    
    }

};

export const handleDownload = (path: any, fileName: any ) => {
    const params = {        
        Bucket: S3_BUCKET,
        Key: `${path}/${fileName}`,
    };

    bucket.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
        alert("Tivemos um problema ao excutar a ação, tente novamente")
      } else {
        console.log(data)
       
        const blob = new Blob([data.Body as BodyPart], { type: "image/jpg" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    });
}

export const invokeLambda = async (fileName: string) => {
    var params = {
        FunctionName:
            "arn:aws:lambda:us-east-1:950864543267:function:ipirangaClassifyFields" /* required */,
        Payload: JSON.stringify({
            pdf_name: fileName,
        }),
        LogType: 'Tail',
        InvocationType: 'RequestResponse',                        
    };

    try {
        let lambda = new AWS.Lambda({
            region: REGION,            
        });

        const lambdaResult = await lambda.invoke(params).promise();

        const resultObject = JSON.parse(lambdaResult.Payload as string)
        const body = JSON.parse(resultObject.body)  
        
        return { data:body[0], error: null}     
            
    } catch (e) {
    
        return { data:null, error: e} 
    }
};
