import React, { FC } from "react";
import {
    AppBar,
    Avatar,
    Box,
    Collapse,
    Grow,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
} from "@mui/material";

import {
    Menu as MenuIcon,
    Settings as SettingsIcon,
} from "@mui/icons-material";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IDrawerMolecule } from "../../../types";
import { Add as AddIcon, Logout as LogoutIcon, Edit as EditIcon } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Spacing from "../../atoms/spacing";
import Text from "../../atoms/text";
import theme from "../../theme";
import logo from "../../../assets/logo.png";
import { useAuth } from "../../../hooks/auth";
import { useDrawer } from "../../../hooks/drawer";
import { PADDING, VERY_PADDING } from "../../../utils/consts";
import { API_URL } from "../../../services";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
    { key: 0, name: "Home", route: "/" },
    { key: 1, name: "Detectar formulário", route: "/upload" },
    { key: 2, name: "Formulários detectados", route: "/forms" },
    { key: 3, name: "Cadastros", route: undefined }
];
const registrationsNavItems = [{
    name: "Usuários", route: "/users",
}, {
    name: "Gerências", route: "/managements",
}, {
    name: "Unidades", route: "/units",
}]
const buttonStyle = {
    color: "#fff",
    marginTop: 5,
    marginRight: 3,
}

const AppHeader: FC = (props: Props) => {
    const registrationsId = "registrations-button";

    const { user, logout } = useAuth();
    const drawer = useDrawer();
    const navigate = useNavigate();

    const [anchorSettings, setAnchorSettings] =
        React.useState<null | HTMLElement>(null);
    const isSettingsOpen = Boolean(anchorSettings);

    const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorSettings(event.currentTarget);
    };

    const handleSettingsMenuClose = () => {
        setAnchorSettings(null);
    };
    const { window } = props;
    const container =
        window !== undefined ? () => window().document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorRegistrations, setAnchorRegistrations] = React.useState<null | HTMLElement>(null);
    const isRegistrationsOpen = Boolean(anchorRegistrations)

    // const getColor = (pathname: string) => {
    //     if (location.pathname == pathname) return theme.palette.primary.main;
    //     return "#000";
    // };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleRegistrationsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorRegistrations(event.currentTarget);
    };

    const handleRegistrationsMenuClose = () => {
        setAnchorRegistrations(null);
    };

    const drawerc = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton sx={{ textAlign: "center" }}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: "flex", position: "absolute" }}>
            <AppBar
                component="nav"
                color="transparent"
                sx={{ boxShadow: "none" }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", sm: "block" },
                        }}
                    ></Typography>
                    <Box
                        sx={{
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        {navItems.map((item, index) => {
                            if (item.route)
                                return <Button key={index} sx={{ ...buttonStyle }} onClick={() => navigate(item.route)}>
                                    <Text color="white" semiBold size={15}>
                                        {item.name}
                                    </Text>
                                </Button>
                            else
                                return <Button key={index} sx={{ ...buttonStyle }} onClick={handleRegistrationsMenuOpen}>
                                    <Text color="white" semiBold size={15}>
                                        {item.name}
                                    </Text>
                                </Button>
                        })}
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                color: "#fff",
                                marginTop: 5,
                                marginRight: 3,
                            }}
                        >
                            <Text color="white" size={15}>
                                Bem-vindo(a) <Text color="white" extraBold size={17} style={{ marginLeft: 5 }}> {user?.name} </Text>
                            </Text>
                        </Button>

                        <IconButton sx={{
                            color: "#fff",
                            marginTop: 5,
                            marginRight: 3,
                        }} color="error" size="small" onClick={logout}>
                            <LogoutIcon fontSize="small" />
                        </IconButton>

                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawerc}
                </Drawer>
            </Box>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
            </Box>

            <Menu
                id={registrationsId}
                anchorEl={anchorRegistrations}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                open={isRegistrationsOpen}
                onClose={handleRegistrationsMenuClose}>
                <Container fluid width="100">
                    {registrationsNavItems.map((subItem, index) => (
                        <MenuItem key={index} onClick={() => {
                            navigate(subItem.route)
                            handleRegistrationsMenuClose();
                        }}>
                            <Text color="black" semiBold size={15}>
                                {subItem.name}
                            </Text>
                        </MenuItem>
                    ))
                    }
                </Container>
            </Menu>
        </Box >
    );
};

export default AppHeader;
