import React, {
    FC,
    createContext,
    useCallback,
    useState,
    useContext,
    useEffect,
} from "react";

import api from "../services";
import { IUser, IAuthContext, IPrivilege } from "../types";
import { IPIRANGA_TOKEN, IPIRANGA_USER } from "../plugins/localStorage.consts";
import { getPrivilegeByUserId } from "../services/privileges";
import { getUser } from "../services/users";

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<any> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);
    const [token, setToken] = useState<string>("");
    const [privilege, setPrivilege] = useState<IPrivilege | null>(null);

    const [loadingUserData, setLoadingUserData] = useState<boolean>(false);

    const clearSession = () => {
        setUser(null);
        setToken("");
        setPrivilege(null);

        localStorage.clear();
    };

    const login = (user: IUser, token: string) => {
        localStorage.setItem(IPIRANGA_USER, JSON.stringify(user));
        localStorage.setItem(IPIRANGA_TOKEN, token);

        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        setUser(user);
        setToken(token);

        fetchUserData(user);
    };

    const refreshUser = (user: IUser) => {
        setUser(user);
    };

    const fetchUserData = async (
        _user: IUser | null,
        _hideLoading?: boolean,
    ) => {
        if (_user) {
            if (!_hideLoading) setLoadingUserData(true);

            const promiseUserData = new Promise(async function (
                resolve,
                reject,
            ) {
                try {
                    if (_user.id) {
                        const auxUser: IUser = await getUser(_user.id);
                        localStorage.setItem(
                            IPIRANGA_USER,
                            JSON.stringify(auxUser),
                        );
                        setUser(auxUser);
                    } else reject(true);
                } catch (_err) {
                    reject(true);
                }
            });

            const promisePrivilege = new Promise(async function (
                resolve,
                reject,
            ) {
                try {
                    if (_user.id) {
                        const privilege: IPrivilege =
                            await getPrivilegeByUserId(_user.id);
                        setPrivilege(privilege);
                        resolve(privilege);
                    } else reject(true);
                } catch (_err) {
                    reject(true);
                }
            });

            Promise.race([promiseUserData, promisePrivilege])
                .then(function (res) {
                    setUser({ ..._user });

                    setTimeout(() => {
                        setLoadingUserData(false);
                    }, 100);
                })
                .catch((_err) => {
                    setTimeout(() => {
                        setLoadingUserData(false);
                    }, 100);
                    clearSession();
                });
        }
    };

    const logout = useCallback(async () => {
        clearSession();
    }, []);

    function isSuperAdmin() {
        if (privilege) {
            return privilege.description == "SUPER_ADMIN";
        }
        return false;
    }

    const hasRights = (route: string) => {        
        return (privilege &&
            privilege.routes &&
            privilege.routes.map((item) => item.path).indexOf(route) >= 0);
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                refreshUser,
                token,
                privilege,
                login,
                logout,
                isSuperAdmin,
                loadingUserData,
                fetchUserData,
                hasRights
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}
