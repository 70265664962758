import React from "react";
import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";

import { colors } from "./styles";

const theme = createTheme(
    {
        palette: {
            primary: {
                main: colors.primary,
                light: colors.primaryLight,
                dark: colors.primaryDark,
            },
            secondary: { main: colors.secondary },
            tertiary: { main: colors.tertiary },
            background: {
                default: colors.background,
                paper: colors.backgroundLighter,
            },

            error: { main: colors.error },
            success: { main: colors.success },

            gray: { main: colors.gray },
            grayLight: { light: colors.grayLighter, main: colors.grayLight },
        },
        typography: {
            fontFamily: "Open Sans",
        },
    },
    ptBR,
);

export default theme;
