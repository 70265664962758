import React, { useState, useRef, useEffect } from "react";

import { BORDER_RADIUS, VERY_PADDING } from "../../utils/consts";
import { uploadFile, invokeLambda } from "../../utils/aws";
import { useForms } from "../../hooks/forms";
import { colors } from "../../components/theme/styles";
import { Grid, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container, Image, Page, Spacing, Text, theme } from "../../components";

import Dialog from "../../components/molecules/modals/dialog";
import backgroundImage from "../../assets/fundo.png";
import uploadIcon from "../../assets/icons/upload.svg";
import infoIcon from "../../assets/icons/info.svg";
import { nanoid } from 'nanoid'

const Upload: React.FC = () => {
    const {
        uploadInference,
        progress,
        setProgress,
        loadingInf,
        setLoadingInf,
        inputFile,
        countProcessingFile,
        setCountProcessingFile,
        status,
        setStatus,
        fileNameProcessing,
        setFileNameProcessing,
        documentWithError,
        setDocumentWithError,
        showModal,
        setShowModal
    } = useForms();

    const navigate = useNavigate();
    const callFileExplorer = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };

    function checkFileNotSupport(response: any) {

        var form_detected = false;

        try {
            if (response?.name_image) {
                response?.name_image.forEach(function (value: any, i: any) {

                    if (response["fields"][i] == "Form not read correctly!") {
                        form_detected = form_detected || false
                    } else {
                        form_detected = form_detected || true
                    }

                });
            }
        } catch {
            form_detected = false
        }

        return form_detected

    }

    const generateHashNamePdf = (name: string) => {
        let hash = nanoid(10)
        let nameWithoutExt = name.split(".")

        let nameHashPdf = `${nameWithoutExt[0].replace(/ /g, "_")}_${hash}.pdf`

        return nameHashPdf
    }


    // TODO need  extract this method
    const handleFileInput = async (e: any) => {
        setLoadingInf(true)
        setCountProcessingFile(e.target.files.length);
        var pdfs = e.target.files;
        var totalPdfs = e.target.files.length;
        var errorPdf = []
        for (const pdf of pdfs) {
            try {

                let nameHashPdf = generateHashNamePdf(pdf.name)

                setFileNameProcessing(pdf.name);

                setStatus("Subindo");

                let upload_success = await uploadFile(
                    pdf,
                    nameHashPdf,
                    setProgress);

                if (!upload_success){
                    errorPdf.push(`${pdf.name} - Problemas ao fazer upload do arquivo`)
                } else {

                    setStatus("Aguarde um momento, estamos lendo os formulários");

                    let result = await invokeLambda(nameHashPdf)

                    let validPdf = checkFileNotSupport(result.data)

                    if (result?.data?.error){
                        errorPdf.push(`${pdf.name} - ${result?.data?.error}`)
                    } else if (!validPdf){
                        errorPdf.push(`${pdf.name} - Inferência não detectou`)
                    } else {
                        result.data["name_pdf"] = nameHashPdf
                        uploadInference(result.data) // need report error
                    }
                }

                totalPdfs = totalPdfs - 1;

                setCountProcessingFile(totalPdfs);

                setStatus("Salvando");

                setStatus("Concluido");
            }
            catch (e) {
                alert(e)
            }
            finally {
                setLoadingInf(false)
            }

        }
        setDocumentWithError(errorPdf)
        setLoadingInf(false)
        setShowModal(true)
    };

    return (
        <Page
            style={{
                backgroundImage: `url(${backgroundImage})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <Container fluid centered height="100vh">
                <Container
                    fluid
                    spacedEvenly
                    hasShadow
                    width="55%"
                    height="75%"
                    color={theme.palette.background.paper}
                    borderRadius={BORDER_RADIUS}
                    sx={{
                        maxWidth: "650px",
                        maxHeight: "500px",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                    }}
                >
                    <Container fluid centered width="100%">
                        <input
                            type="file"
                            id="file"
                            accept="application/pdf"
                            ref={inputFile}
                            multiple
                            onChange={handleFileInput}
                            hidden
                            style={{ display: "none" }}
                        />
                        <IconButton disabled={loadingInf} onClick={() => callFileExplorer()}>
                            <Image source={uploadIcon} style={{ margin: 50 }} />
                        </IconButton>

                        <Spacing top={VERY_PADDING + 20} />
                        <Text semiBold color={colors.primary} size={14}>
                            Clique a cima pra selecionar
                        </Text>
                        <Text semiBold color={colors.primary} size={14}>
                            os documentos desejados
                        </Text>
                        <Spacing top={VERY_PADDING + 20} />

                        <Spacing top={VERY_PADDING + 20} />

                        <Text
                            semiBold
                            color={colors.primary}
                            size={14}
                            style={{
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                        >
                            Arquivo em Processamento:
                            <Text
                                semiBold
                                color={colors.primary}
                                size={20}
                                style={{ marginLeft: 6, marginTop: 50, textAlign: "center" }}
                            >
                                {countProcessingFile}
                            </Text>
                        </Text>
                        {fileNameProcessing && (
                            <Text
                                color={colors.primary}
                                size={10}
                                style={{
                                    justifyContent: "center",
                                    alignContent: "center",
                                    margin: 10,
                                }}
                            >
                                {status}{" "}
                                <Text bold color={colors.primary} size={10}>
                                    {fileNameProcessing}
                                </Text>
                                {status == "Subindo" && <Text semiBold size={16} color={colors.success}>

                                    <Text bold color={colors.primary} size={10}>
                                        :
                                    </Text>{" "}{progress}%
                                </Text>}
                            </Text>
                        )}
                    </Container>
                </Container>
            </Container>
            <Dialog
                open={showModal}
                message={"Operação realizada"}
                onClose={() => { setShowModal(false); navigate("/upload") }}
                onCancel={() => { setShowModal(false); navigate("/upload") }}
                onConfirm={() => { setShowModal(false); navigate("/forms") }}

                cancelLabel={"Novo upload"}

                loading={false}

                confirmLabel={"Formuários"}
            >
                <>
                    {
                        documentWithError.length > 0 && <Text bold color={colors.primary} size={10} style={{ margin: 5, marginTop: 20 }}>
                            Os PDFs listados não foram reconhecidos:
                        </Text>
                    }

                    {
                        documentWithError.map(
                            (pdfName: any, index: number) => {
                                return (
                                    <Text key={index} bold color={colors.grayDark} size={10} style={{ margin: 5 }}>
                                        {pdfName}
                                    </Text>)
                            })
                    }
                </>
            </Dialog>

        </Page>
    );
};

export default Upload;
