import React, { FC } from 'react'

import { BORDER_RADIUS } from '../../../utils/consts'
import { Button, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { IInformation } from '../../../types/components/molecules/modals'

import Container from '../../atoms/container'
import Modal from '../../atoms/modal'
import Spacing from '../../atoms/spacing'
import Text from '../../atoms/text'
import Theme from '../../theme'

const Information: FC<IInformation> = (params: IInformation) => {
    return (
        <Modal isOpened={params.open} onClose={params.onClose} disableBackdropClick>
            <Container fluid color={Theme.palette.background.default} hasShadow centered borderRadius={10}>
                {params.title && <Container inline color={Theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container style={{ flexGrow: 1 }} />
                    <Text size={16} bold color={Theme.palette.primary.light}>{params.title}</Text>
                    <Container style={{ flexGrow: 1 }} />
                    <IconButton size="small" style={{}} onClick={params.onClose}>
                        <CloseIcon fontSize="small" style={{ color: Theme.palette.primary.light }} />
                    </IconButton>
                </Container>}

                <Container fluid spacedAround veryPadded centered>
                    <Spacing top={10} />
                    {params.message && <Text bold center>{params.message}</Text>}
                    {params.children}


                    <Spacing top={30} />
                    <Button variant="contained" size="small" style={{ backgroundColor: Theme.palette.primary.light, minWidth: '130px' }}
                        onClick={params.onConfirm}>
                        <Text bold size={12} color={Theme.palette.background.paper}>Fechar</Text>
                    </Button>
                </Container>
            </Container>
        </Modal>
    )
}

export default Information;