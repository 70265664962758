import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/auth";
import { login as loginService } from "../../services/auth";
import { IUser } from "../../types";

import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../utils/consts";
import { colors } from "../../components/theme/styles";

import logo from "../../assets/pix_logo.png";
import backgroundImage from "../../assets/fundo.png";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
    Container,
    Image,
    Input,
    Page,
    Spacing,
    Text,
    theme,
} from "../../components";

const Home: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Page
            style={{
                backgroundImage: `url(${backgroundImage})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <Container fluid centered height="100vh">
                <Text
                    semiBold
                    color="white"
                    // style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                    size={50}
                >
                    Detecção de formulário
                </Text>
                <Spacing top={20} />
                <Text
                    semiBold
                    color="white"
                    // style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                    size={15}
                >
                    Selecione uma ação abaixo pra començar.
                </Text>
                <Spacing top={60} />
                <Stack spacing={2} direction="row">
                    <Button
                        variant="contained"
                        component="label"
                        color="secondary"
                        size="large"
                        sx={{ width: 250 }}
                        onClick={() => navigate("/upload")}
                    >
                        <Text semiBold size={15}>
                            Novo Upload
                        </Text>
                        {/* <input hidden accept="image/*" multiple type="file" /> */}
                    </Button>
                    <Button
                        size="large"
                        variant="outlined"
                        color="secondary"
                        sx={{ width: 250 }}
                    >
                        <Text
                            semiBold
                            color="white"
                            // style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                            onClick={() => navigate("/forms")}
                            size={15}
                        >
                            Formulários
                        </Text>
                    </Button>
                </Stack>
            </Container>
        </Page>
    );
};

export default Home;
