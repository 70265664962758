import React, { FC } from "react";

import { IDialog } from "../../../types/components/molecules/modals";
import { LoadingButton } from "@mui/lab";

import Container from "../../atoms/container";
import Modal from "../../atoms/modal";
import Spacing from "../../atoms/spacing";
import Text from "../../atoms/text";
import Theme from "../../theme";

const Confirmation: FC<IDialog> = (params: IDialog) => {
    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container
                fluid
                color={Theme.palette.background.default}
                hasShadow
                centered
                style={{ maxWidth: 440, borderRadius: "4px" }}
            >
                <Container fluid spacedAround veryPadded centered>
                    <Spacing top={10} />
                    {params.message && (
                        <Text bold center>
                            {params.message}
                        </Text>
                    )}
                    {params.children}
                </Container>

                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{
                            opacity: params.loading ? 0.5 : 1,
                            borderColor: Theme.palette.primary.light,
                            width: 150,
                            height: 35,
                        }}
                        loading={params.loading}
                        disabled={params.loading}
                        onClick={params.onCancel}
                    >
                        <Text
                            bold
                            size={12}
                            color={Theme.palette.primary.light}
                        >
                            {params.cancelLabel}
                        </Text>
                    </LoadingButton>
                    <Spacing left={30} />
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading}
                        disabled={params.loading}
                        style={{
                            backgroundColor: params.loading
                                ? Theme.palette.grayLight.main
                                : Theme.palette.primary.light,
                            width: 150,
                            height: 35,
                        }}
                        onClick={params.onConfirm}
                    >
                        <Text
                            bold
                            size={12}
                            color={Theme.palette.background.paper}
                        >
                            {params.confirmLabel}
                        </Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    );
};

export default Confirmation;
