import React, { FC } from "react";
import { IconButton, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { IInput } from "../../../types";

const Input: FC<IInput> = (params: IInput) => {
    return (
        <TextField
            id={params.id}
            autoFocus={params.autoFocus}
            required={params.required}        
            placeholder={params.placeholder}
            label={params.label}
            value={params.value}
            error={params.error}
            disabled={params.disabled}
            onChange={params.onChange}
            multiline={params.multiline}
            maxRows={4}
            variant={params.variant || "standard"}
            size={params.size || "small"}
            style={{
                ...params.sx,
            }}
            InputProps={{
                startAdornment: params.startAdornment,
                endAdornment: params.isPassword ? (
                    <IconButton
                        size="small"
                        aria-label="passw-togle-visibility"
                        onClick={params.handleClickShowPassword}
                        onMouseDown={params.handleClickShowPassword}
                        edge="end"
                    >
                        {params.showPassword ? (
                            <VisibilityOff />
                        ) : (
                            <Visibility />
                        )}
                    </IconButton>
                ) : (
                    params.endAdornment
                ),
                ...params.inputProps,
            }}
            type={
                !params.isPassword
                    ? params.type
                    : params.showPassword
                    ? "text"
                    : "password"
            }
            {...params.register}
        />
    );
};

export default Input;
