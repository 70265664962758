import React, { FC, useState } from 'react';
import { Cell, PieChart as RechartPieChart, Pie, Sector, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { IDashboardBarChart } from '../../../../types';
import { BORDER_RADIUS, VERY_PADDING } from '../../../../utils/consts';

const COLORS = [
    theme.palette.primary.light, theme.palette.primary.main, theme.palette.primary.dark,
    theme.palette.warning.light, theme.palette.warning.main, theme.palette.warning.dark
];

const DashboardPieChart: FC<IDashboardBarChart> = (params: IDashboardBarChart) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const onPieEnter = (_: any, index: any) => {
        setActiveIndex(index);
    };

    return (
        <Container fluid flex hasShadow veryPadded borderRadius={BORDER_RADIUS} color={theme.palette.background.paper}>
            <Container fluid width="100%">
                <Text bold size={15}>{params.label}</Text>
            </Container>
            <Spacing top={VERY_PADDING} />
            <Container fluid flex centered color={params.color || theme.palette.info.dark}>
                <ResponsiveContainer width="90%" height="100%" >
                    <RechartPieChart
                        width={500}
                        height={300}>
                        <Legend verticalAlign="top" height={36} />
                        <Tooltip />
                        <Pie label data={params.values} dataKey={params.labelValueA} fill={theme.palette.primary.dark}
                            // onMouseEnter={onPieEnter} activeIndex={activeIndex} activeShape={renderActiveShape}
                            paddingAngle={params.paddingAngle}
                            innerRadius={params.innerRadius}
                            outerRadius={params.outerRadius}>
                            {params.values.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        {params.labelValueB &&
                            <Pie
                                dataKey={params.labelValueB}
                                fill={theme.palette.primary.light}
                                innerRadius={70} outerRadius={90} label />}
                    </RechartPieChart>
                </ResponsiveContainer>
            </Container>
        </Container>
    );
}

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Quantidade ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Porcentagem ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default DashboardPieChart;