import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Divider, Grid, IconButton, SelectChangeEvent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@mui/icons-material";
import moment from "moment";

import backgroundImage from "../../../assets/fundo.png";
import { Container, Input, UnitSelect, Page, Spacing, Text, theme, } from "../../../components";
import { useInformation } from "../../../hooks/information";
import { useUnits } from "../../../hooks/units";
import { useUsers } from "../../../hooks/users";
import { IUser, ISelectValue } from "../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';

interface Props {
    id?: string;
}

const UsersRegister: FC<Props> = (props: Props) => {
    const labelColor: string = theme.palette.grey[600];
    
    const navigate = useNavigate();
    
    let { id } = useParams();
    
    /** 
    * Route coming from the id {props.id} of the logged in user, 
    * allowing him to edit his own registration
    */
    id = props.id || id 

    const isEdit = id != undefined;

    const { hideInformation, showInformation } = useInformation();
    const { fetchUnits } = useUnits();
    const { createNewUser, editUser, fetchUser } = useUsers();

    const [editItem, setEditItem] = useState<IUser | undefined>(undefined);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string | undefined>(undefined);
    const [unit, setUnit] = useState<any>(undefined);
    const [unitName, setUnitName] = useState<string>('');
    const [unitError, setUnitError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {        
        fetchData(id);
    }, []);

    useEffect(() => {
        if (!editItem) return;

        setEmail(editItem.email);
        setFirstName(editItem.profile.firstName || '');
        setLastName(editItem.profile.lastName || '');
        if (editItem.unit && editItem.unit.name) {
            setUnit({
                id: editItem.unit.id,
                label: editItem.unit.name
            });
            setUnitName(editItem.unit.name);
        }
    }, [editItem]);

    const fetchData = async (_id: string | undefined) => {
        if (isEdit && _id) {
            let auxUser = await fetchUser(_id);
            setEditItem(auxUser);
        }

        fetchUnits();
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }

    const isValid = () => {
        let valid: boolean = true;

        if (email == '') {
            setEmailError('Favor informar o e-mai');
            valid = false;
        }
        if (id == undefined && password == '') {
            setPasswordError('Favor informar a senha');
            valid = false;
        }
        if (firstName == '') {
            setFirstNameError('Favor informar o primeiro nome');
            valid = false;
        }
        if (lastName == '') {
            setLastNameError('Favor informar o segundo nome');
            valid = false;
        }
        if (unit == undefined) {
            setUnitError('Favor informar a gerência');
            valid = false;
        }

        if (valid) {
            setEmailError(undefined);
            setPasswordError(undefined);
            setFirstNameError(undefined);
            setLastNameError(undefined);
            setUnitError(undefined);
            setError(undefined);
        }

        return valid;
    }

    const save = async () => {
        if (!isValid()) return;

        try {
            let surname: string = firstName;
            if (lastName != '')
                surname += ' ' + lastName;
            
            
            setLoading(true);

            let payload: IUser = {
                email,
                password,
                firstName,
                lastName,
                surname: surname,
                name: surname,
                status: 1,
                unitId: unit ? unit.id : undefined
            }

            console.log(payload)

            let successMessage: string = '';
            if (isEdit && editItem && editItem.id) {
                payload.id = editItem.id;

                await editUser(editItem.id, payload);
                successMessage = 'Usuário editado com sucesso.'
            } else {
                await createNewUser(payload, false);
                successMessage = 'Usuário criado com sucesso.'
            }

            setLoading(false);
            showInformation(successMessage, undefined, undefined, () => {
                navigate('/users');

                cleanData();
                hideInformation();
            });
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            }
            setLoading(false);
        }
    }

    const cleanData = () => {
        setEditItem(undefined);
        setEmail('');
        setEmailError(undefined);
        setPassword('');
        setPasswordError(undefined);
        setFirstName('');
        setFirstNameError(undefined);
        setLastName('');
        setLastNameError(undefined);
        setUnit(undefined);
        setUnitName('');
        setUnitError(undefined);
        setError(undefined);
    }


    return (
        <Page
            style={{
                backgroundImage: `url(${backgroundImage})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <Container fluid centered width="100%" height="100vh">
                <Container
                    fluid
                    hasShadow
                    width="100%"
                    height="100%"
                    color={theme.palette.background.paper}
                    borderRadius={BORDER_RADIUS}
                    horizontalCentered
                    sx={{
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                        marginTop: "120px",
                    }}>
                    <Spacing top={PADDING} />
                    <Container fluid padded width="1080px" hasShadow color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}>
                        <Container inline horizontalCentered>
                            <Link to="/users">
                                <IconButton color="primary" size="small">
                                    <KeyboardArrowLeftIcon fontSize="large" />
                                </IconButton>
                            </Link>
                            <Spacing left={PADDING} />
                            <Text bold size={22} color={theme.palette.primary.main}>Usuários - {!isEdit ? 'Novo' : 'Editar'}</Text>
                        </Container>
                        <Spacing top={PADDING} />
                        <Grid container columnSpacing={2} rowSpacing={3} alignItems="center" sx={{ paddingRight: 2 }}>
                            <Grid item xs={6}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*E-mail:</Text>
                                    <Input
                                        variant="outlined"
                                        type="text"
                                        value={email}
                                        onChange={handleChangeEmail}
                                        disabled={loading}
                                        autoComplete={false}
                                        placeholder='Informe o e-mail' />
                                    {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*Senha:</Text>
                                    <Input
                                        id="passw"
                                        variant="outlined"
                                        type="text"
                                        value={password}
                                        onChange={handleChangePassword}
                                        disabled={loading}
                                        placeholder='Informe a senha'
                                        autoComplete={false}
                                        isPassword
                                        showPassword={showPassword}
                                        handleClickShowPassword={() => setShowPassword(!showPassword)} />
                                    {id != undefined && <Text size={13} color={theme.palette.error.main} style={{ paddingTop: PADDING / 2 }}>Deixe a senha vazia caso não queira alterá-la.</Text>}
                                    {passwordError && <Text size={13} color={theme.palette.error.main} >{passwordError}</Text>}
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*Nome:</Text>
                                    <Input
                                        variant="outlined"
                                        type="text"
                                        value={firstName}
                                        onChange={handleChangeFirstName}
                                        disabled={loading}
                                        placeholder='Informe o primeiro nome' />
                                    {firstNameError && <Text size={13} color={theme.palette.error.main} >{firstNameError}</Text>}
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*Sobrenome:</Text>
                                    <Input
                                        variant="outlined"
                                        type="text"
                                        value={lastName}
                                        onChange={handleChangeLastName}
                                        disabled={loading}
                                        placeholder='Informe o segundo nome' />
                                    {lastNameError && <Text size={13} color={theme.palette.error.main} >{lastNameError}</Text>}
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container fluid padded width="100%" height="60px">
                                    <Text light size={15} color={labelColor}>*Unidade:</Text>
                                    <UnitSelect
                                        value={unit}
                                        setValue={setUnit}
                                        inputValue={unitName}
                                        setInputValue={setUnitName}
                                        disabled={loading}
                                        variant="outlined"
                                        error={unitError} />
                                </Container>
                            </Grid>
                        </Grid>

                        <Container fluid flex padded>
                            {error && <Text size={13} color={theme.palette.error.main} >{error}</Text>}
                            <Spacing top={VERY_PADDING * 2} />
                            <LoadingButton
                                variant="contained"
                                size="small"
                                loading={loading}
                                disabled={loading}
                                style={{ backgroundColor: loading ? theme.palette.grayLight.light : theme.palette.info.main, width: 150, height: 35 }}
                                onClick={() => save()}>
                                <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                            </LoadingButton>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page>
    );
}

export default UsersRegister;