import React, { FC, createContext, useContext, useState } from 'react'

import { createUnit, updateUnit, getUnits, destroyUnit, getUnit } from '../services/units';
import { IUnit, IUnitContext } from '../types'

const UnitsContext = createContext<IUnitContext>({} as IUnitContext);

export const UnitsProvider: FC<any> = ({ children }) => {
    const [units, setUnits] = useState<IUnit[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchUnit, setSearchUnit] = useState<string>('');
    const [unitsPerRow, setUnitsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchUnits = async () => {
        setLoading(true);
        try {
            const units: IUnit[] = await getUnits();
            setUnits([...units]);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchUnit = async (_id: string) => {
        const unit: IUnit = await getUnit(_id);
        return unit;
    }

    const createNewUnit = async (_unit: IUnit, _reload: boolean = true) => {
        try {
            const unit = await createUnit(_unit);
            if (_reload)
                fetchUnits();
            return unit;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const editUnit = async (_id: string, _payload: IUnit) => {
        try {
            const unit = await updateUnit(_id, _payload);

            fetchUnits();
            return unit;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteUnit = async (_id: string) => {
        const unit = await destroyUnit(_id);
        fetchUnits();
        return unit;
    }

    return (
        <UnitsContext.Provider value={{
            units, loading, fetchUnits, fetchUnit,
            createNewUnit, editUnit, deleteUnit,
            searchUnit, setSearchUnit, unitsPerRow, setUnitsPerRow, paginate, setPaginate
        }}>{children}</UnitsContext.Provider>
    )
}

export function useUnits() {
    const context = useContext(UnitsContext);

    if (!context) {
        throw new Error('useUnits must be used within an UnitsProvider')
    }

    return context;
}
