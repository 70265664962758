import React, { useState, useEffect, useCallback, useRef } from "react";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/auth";
import { useForms } from "../../hooks/forms";

import { login as loginService } from "../../services/auth";
import Text from "../../components/atoms/text";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { handleDownload } from "../../utils/aws";

import TextField from '@mui/material/TextField';
import { IUser, IListHeader, IFormsFilterParams } from "../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../utils/consts";
import { colors } from "../../components/theme/styles";
import { Grid, Box, IconButton } from "@mui/material";
import { useConfirmation } from '../../hooks/confirmation';
import { UnitSelect } from "../../components";
import { useUnits } from "../../hooks/units";
import Stack from "@mui/material/Stack";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DateRangePicker from "../../components/atoms/dateRangePicker";


import SaveIcon from '@mui/icons-material/Save';

import backgroundImage from "../../assets/fundo.png";
import logo from "../../assets/pix_logo.png";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


import {
  Container,
  Loading,
  Page,
  Confirmation,
  theme,
} from "../../components";

const Upload: React.FC = () => {

  const {
    loading,
    forms,
    fetchForms,
  } = useForms();


  const [initialDate, setInitialDate] = useState<Date | null>(new Date());
  const [finalDate, setFinalDate] = useState<Date | null>(new Date());
  const [URLImage, setURLImage] = useState<string>("");

  const [modalForm, setModalForm] = useState<boolean>(false);
  const gridRef = useRef<any>(null);

  useEffect(() => {

    const filterParams: IFormsFilterParams = {
      initialDate,
      finalDate,
      unitId: undefined,
    };
    fetchForms(filterParams);

  }, [initialDate, finalDate]);


  const onGridReady = useCallback(() => {

    const filterParams: IFormsFilterParams = {
      initialDate,
      finalDate,
      unitId: undefined,
    };
    fetchForms(filterParams);
  }, []);


  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }, []);


  const statusFormatter = (params: any) => {

    var color = "green"

    const success = params.value == "Sucesso"

    if (!success) {
      color = "red"
    }

    return (
      <Text color={color} size={12}>
        {params.value}
      </Text>
    )
  };

  const dateFormatter = (params: any) => {
    const date = new Date(params.value)
    return date.toLocaleString('pt-BR', { hour12: false })
  };

  const modalShowImage = (url: string) => {
    setModalForm(true)
    setURLImage(url)
  }

  const cellRenderer = (params: any) => {
    var url = params.value
    return (
      <span>
        <img onClick={() => modalShowImage(url)} width={60} height={60} src={url} />
      </span>
    );
  }

  var filterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {

      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split(' ');
      var _dateParts = dateParts[0].split('-');

      var cellDate = new Date(
        Number(_dateParts[0]),
        Number(_dateParts[1]) - 1,
        Number(_dateParts[2])
      );

      var filterLocalDateAtMidnight = new Date(filterLocalDateAtMidnight)

      if (filterLocalDateAtMidnight === cellDate) {
        return 1;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0
    },
    browserDatePicker: true,
    minValidYear: 2000,
    maxValidYear: 2025,
    inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
  };

  const getParams = () => {
    return {
      allColumns: true,
      fileName: `${initialDate?.toLocaleDateString("pt-BR")}__${finalDate?.toLocaleDateString("pt-BR")}.csv`
    };
  };

  const downloadImg = (linkImage: string) => {

    var link = document.createElement('a');
    link.href = linkImage;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv(getParams());
  }, []);


  const [columnDefs] = useState([
    { field: "id", headerName: "id", resizable: true, width: 60, suppressMenu: true, hide: true },
    { field: 'createdAt', headerName: "Data Ocorrência", valueFormatter: dateFormatter, resizable: true, filterParams: filterParams, },
    { field: "company", headerName: "Empresa" },
    { field: 'managerCode', headerName: "Gerência", resizable: true, width: 100, },
    { headerName: "Nucleo", hide: true },
    { field: 'unidadeCode', headerName: "Unidade", resizable: true, width: 100, },

    { field: 'local', headerName: "Local", resizable: true, },
    { headerName: "Local Outro", hide: true },

    { field: 'desvio', headerName: "Desvio", resizable: true, },
    { headerName: "Descrição Desvio", hide: true },

    { field: 'desvioEpi', headerName: "Desvio EPI Tipo", resizable: true, },
    { headerName: "Desvio de EPI Outro", hide: true },

    { field: 'transporte', headerName: "Tipo Desvio Transporte", resizable: true, },
    { headerName: "Tipo Desvio Infraestrutura", hide: true, resizable: true, },
    { headerName: "Tipo Desvio Sinalização", hide: true, resizable: true, },
    { field: 'equipamento', headerName: "Equipamento", resizable: true, },
    { headerName: "Equipamento Outro", hide: true, resizable: true, },

    { field: 'desvioTratado', headerName: "Desvio tratado", resizable: true, },

    { headerName: "Tratativa", hide: true, resizable: true, },
    { headerName: "Transportadora", hide: true, resizable: true, },

    { field: 'nameUser', headerName: "Nome", resizable: true, },
    { field: "email", headerName: "E-mail", resizable: true, },
    { headerName: "Tipo Entrada", hide: true, resizable: true, },
    { headerName: "Sugestão", hide: true, resizable: true, },
    { headerName: "Status", hide: true, resizable: true, },
    { headerName: "Andar", hide: true, resizable: true, },
    { headerName: "Ala", hide: true, resizable: true, },

    { field: 'name', headerName: "Nome formulário", resizable: true, autoHeight: true },
    { field: 'problemInDetection', headerName: "Status inferência", cellRenderer: statusFormatter, width: 95 },
    { field: 'imagemLink', headerName: "Formulário", cellRenderer: cellRenderer, resizable: true, autoHeight: true, width: 120 },
  ]);


  return (
    <Page
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >

      <Container fluid centered height="100vh">
        <Container
          hasShadow
          width="100%"
          height="100%"
          color={theme.palette.background.paper}
          borderRadius={BORDER_RADIUS}
          sx={{
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            marginTop: "120px",
          }}
        >
          <Container style={{ width: 1000, margin: 20 }} inline>
            <DateRangePicker
              initialDate={initialDate}
              finalDate={finalDate}
              placeholder={"Filtro por data"}
              setInitialDate={setInitialDate}
              setFinalDate={setFinalDate}
              disabled={loading}
              maxDaysSelect={32}
            />
            <Box sx={{ width: 200, marginLeft: 3 }}>

              <TextField id="filter-text-box" label="Busca geral" size="small" variant="outlined" onInput={onFilterTextBoxChanged} />
            </Box>

            <LoadingButton
              loading={false}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              style={{ marginLeft: 20 }}

              onClick={() => onBtnExport()}
            >
              Gerar relatório
            </LoadingButton>
          </Container>
          <Container className="ag-theme-material" width="100%" style={{ flex: '1 1 auto', height: 'calc(100% - 90px)' }}>
            <AgGridReact
              ref={gridRef}
              rowData={forms}
              columnDefs={columnDefs}
              debounceVerticalScrollbar
              paginationPageSize={5}
              loadingCellRenderer={true}
              suppressExcelExport={true}
              pagination={true}
              animateRows={true}
              onGridReady={onGridReady}
              overlayNoRowsTemplate={'<span>Sem registros de formulários nas datas selecionadas</span>'}
              paginationAutoPageSize={true}>
            </AgGridReact>
          </Container>
          <Loading
            open={loading}
            message={"Buscando formulários"}
          />

          <Confirmation
            open={modalForm}
            onClose={() => setModalForm(false)}
            onConfirm={() => downloadImg(URLImage)}
            onCancel={() => setModalForm(false)}
            cancelLabel={"Fechar"}
            confirmLabel={"Salvar"}
            children={<img max-width={500} height={500} src={URLImage} />}
          />
        </Container>
      </Container>
    </Page>
  );
};

export default Upload;
