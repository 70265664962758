import React, { FC } from 'react';

import { IManagementSelect } from '../../../../types';
import { useManagements } from '../../../../hooks/managements';

import AutocompleteNew from '../../../atoms/autocomplete/new';
import Container from '../../../atoms/container';
import theme from '../../../theme';

const ManagementSelect: FC<IManagementSelect> = (params: IManagementSelect) => {
    const { managements } = useManagements();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <AutocompleteNew
                id="managements"
                required
                value={params.value}
                setValue={params.setValue}
                inputValue={params.inputValue}
                setInputValue={params.setInputValue}
                variant={params.variant || 'standard'}
                options={managements as any[]}
                placeHolder={params.placeholder || 'Escolha a gerência'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists />
        </Container>
    );
}

export default ManagementSelect;