import { AxiosResponse } from "axios";

import api from ".";
import { IRoute } from "../types";

const baseUrl: string = "routes";

export const createRoute = async (_payload: IRoute) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, _payload);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const position: IRoute = convertApiResponseToIRoute(response.data);
      return position;
    }
  }

  throw new Error("Não foi possível criar uma nova rota");
};

export const updateRoute = async (_positionId: string, _payload: IRoute) => {
  const response: AxiosResponse = await api.put(
    `/v1_0/${baseUrl}/${_positionId}`,
    _payload
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const position: IRoute = convertApiResponseToIRoute(response.data);
      return position;
    }
  }

  throw new Error("Não foi possível criar uma nova rota");
};

export const deleteRoute = async (_positionId: string) => {
  const response: AxiosResponse = await api.delete(
    `/v1_0/${baseUrl}/${_positionId}`
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const position: IRoute = convertApiResponseToIRoute(response.data);
      return position;
    }
  }

  throw new Error("Não foi possível criar uma nova rota");
};

export const getRoutes = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const routes: IRoute[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];

        const position: IRoute = convertApiResponseToIRoute(data);
        routes.push(position);
      }

      return routes;
    }
  }

  throw new Error("Não foi possível buscar os cargos");
};

export const getRoute = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const route: IRoute = convertApiResponseToIRoute(response.data);
      return route;
    }
  }

  throw new Error("Não foi possível buscar a rota");
};

export const convertApiResponseToIRoute = (_data: any) => {
  const position: IRoute = {
    id: _data.id,
    description: _data.description,
    path: _data.path,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return position;
};
