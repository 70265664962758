import React, { FC, createContext, useState, useContext } from 'react'

import { fetchClassificationControl, fetchClassificationQuality, fetchDentitionControl, fetchSexControl, fetchWeightRangeControl, getAnnotationsThisWeekCount, getCarcassAllTimeCount, getCarcassesCountByClassification, getCarcassesCountByDentition, getCarcassesCountBySex, getCarcassThisMonthCount, getCarcassThisWeekCount, getCompaniesCount, getRevisionsThisWeekCount, getUsersCount, getCarcassesCountByWeight, getCarcassesCountAnnotationsXRevisions, getCarcassesCountPerClassificationsAnnotationsXRevisions } from '../services/dashboard'
import { IDashClassificationControl, IDashClassificationQuality, IDashDentitionControl, IDashSexControl, IDashWeightRangeControl, IDashboardContext, IDashboardBarChartData, ICompany, IDashboardFilterData } from '../types'

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

export const DashboardProvider: FC<any> = ({ children }) => {
    const [carcassThisWeekCount, setCarcassThisWeekCount] = useState<number>(0);
    const [carcassThisMonthCount, setCarcassThisMonthCount] = useState<number>(0);
    const [carcassAllTimeCount, setCarcassAllTimeCount] = useState<number>(0);
    const [companiesCount, setCompaniesCount] = useState<number>(0);
    const [usersCount, setUsersCount] = useState<number>(0);
    const [carcassesCountByClassification, setCarcassesCountByClassification] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByDentition, setCarcassesCountByDentition] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByWeight, setCarcassesCountByWeight] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountBySex, setCarcassesCountBySex] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountAnnotationsXRevisions, setCarcassesCountAnnotationsXRevisions] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountPerClassificationsAnnotationsXRevisions, setCarcassesCountPerClassificationsAnnotationsXRevisions] = useState<IDashboardBarChartData[]>([]);
    const [annotationsThisWeekCount, setAnnotationsThisWeekCount] = useState<number>(0);
    const [revisionsThisWeekCount, setRevisionsThisWeekCount] = useState<number>(0);

    const [classificationControlData, setClassificationControlData] = useState<IDashClassificationControl[]>([]);
    const [classificationQualityData, setClassificationQualityData] = useState<IDashClassificationQuality[]>([]);
    const [dentitionControlData, setDentitionControlData] = useState<IDashDentitionControl[]>([]);
    const [sexControlData, setSexControlData] = useState<IDashSexControl[]>([]);
    const [weightRangeData, setWeightRangeData] = useState<IDashWeightRangeControl[]>([]);

    const [filter, setFilter] = useState<IDashboardFilterData>({ companies: [] });


    const fetchCarcassThisWeekCount = async (_query: string) => {
        let count = await getCarcassThisWeekCount(_query);
        setCarcassThisWeekCount(count);
    }
    const fetchCarcassThisMonthCount = async (_query: string) => {
        let count = await getCarcassThisMonthCount(_query);
        setCarcassThisMonthCount(count);
    }
    const fetchCarcassAllTimeCount = async (_query: string) => {
        let count = await getCarcassAllTimeCount(_query);
        setCarcassAllTimeCount(count);
    }
    const fetchCompaniesCount = async (_query: string) => {
        let count = await getCompaniesCount(_query);
        setCompaniesCount(count);
    }
    const fetchUsersCount = async (_query: string) => {
        let count = await getUsersCount(_query);
        setUsersCount(count);
    }
    const fetchCarcassesCountByClassification = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountByClassification(_query);
        setCarcassesCountByClassification(data);
    }
    const fetchCarcassesCountByDentition = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountByDentition(_query);
        setCarcassesCountByDentition(data);
    }
    const fetchCarcassesCountByWeight = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountByWeight(_query);
        setCarcassesCountByWeight(data);
    }
    const fetchCarcassesCountBySex = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountBySex(_query);
        setCarcassesCountBySex(data);
    }
    const fetchCarcassesCountAnnotationsXRevisions = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountAnnotationsXRevisions(_query);
        setCarcassesCountAnnotationsXRevisions(data);
    }
    const fetchCarcassesCountPerClassificationsAnnotationsXRevisions = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountPerClassificationsAnnotationsXRevisions(_query);
        setCarcassesCountPerClassificationsAnnotationsXRevisions(data);
    }
    const fetchAnnotationsThisWeekCount = async (_query: string) => {
        let count = await getAnnotationsThisWeekCount(_query);
        setAnnotationsThisWeekCount(count);
    }
    const fetchRevisionsThisWeekCount = async (_query: string) => {
        let count = await getRevisionsThisWeekCount(_query);
        setRevisionsThisWeekCount(count);
    }

    const fetchDashboardData = async (_filter: IDashboardFilterData) => {
        let query: string = '';
        if (_filter.companies.length > 0)
            query += `?companies=${_filter.companies.map(item => `'${item.id}'`).join(',')}`;

        setCarcassThisWeekCount(0);
        setCarcassThisMonthCount(0);
        setCarcassAllTimeCount(0);
        setCompaniesCount(0);
        setUsersCount(0);
        setAnnotationsThisWeekCount(0);
        setRevisionsThisWeekCount(0);

        const promiseCarcassThisWeekCount = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassThisWeekCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassThisMonthCount = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassThisMonthCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassAllTimeCount = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassAllTimeCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCompaniesCount = new Promise(async function (resolve, reject) {
            try {
                fetchCompaniesCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseUsersCount = new Promise(async function (resolve, reject) {
            try {
                fetchUsersCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByClassification = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByClassification(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByDentition = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByDentition(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByWeight = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByWeight(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountBySex = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountBySex(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountAnnotationsXRevisions = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountAnnotationsXRevisions(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountPerClassificationsAnnotationsXRevisions = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountPerClassificationsAnnotationsXRevisions(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseAnnotationsThisWeekCount = new Promise(async function (resolve, reject) {
            try {
                fetchAnnotationsThisWeekCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseRevisionsThisWeekCount = new Promise(async function (resolve, reject) {
            try {
                fetchRevisionsThisWeekCount(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });

        Promise.race([
            promiseCarcassThisWeekCount,
            promiseCarcassThisMonthCount,
            promiseCarcassAllTimeCount,
            promiseCompaniesCount,
            promiseCompaniesCount,
            promiseUsersCount,
            promiseCarcassesCountByClassification,
            promiseCarcassesCountByDentition,
            promiseCarcassesCountByWeight,
            promiseCarcassesCountBySex,
            promiseCarcassesCountAnnotationsXRevisions,
            promiseCarcassesCountPerClassificationsAnnotationsXRevisions,
            promiseAnnotationsThisWeekCount,
            promiseRevisionsThisWeekCount
        ]);
    }

    const fetchClassificationControlData = async () => {
        const data = await fetchClassificationControl();
        if (data)
            setClassificationControlData(data)
    }

    const fetchClassificationQualityData = async () => {
        const data = await fetchClassificationQuality();
        if (data)
            setClassificationQualityData(data)
    }

    const fetchDentitionControlData = async () => {
        const data = await fetchDentitionControl();
        if (data)
            setDentitionControlData(data)
    }

    const fetchSexControlData = async () => {
        const data = await fetchSexControl();
        if (data)
            setSexControlData(data)
    }

    const fetchWeightRangeControlData = async () => {
        const data = await fetchWeightRangeControl();
        if (data)
            setWeightRangeData(data)
    }


    return (
        <DashboardContext.Provider value={{
            carcassThisWeekCount, carcassThisMonthCount, carcassAllTimeCount,
            companiesCount, usersCount,
            carcassesCountByClassification,
            carcassesCountByDentition,
            carcassesCountByWeight,
            carcassesCountBySex,
            carcassesCountAnnotationsXRevisions,
            carcassesCountPerClassificationsAnnotationsXRevisions,
            annotationsThisWeekCount,
            revisionsThisWeekCount,

            fetchDashboardData,

            classificationControlData,
            fetchClassificationControlData,
            classificationQualityData,
            fetchClassificationQualityData,
            dentitionControlData,
            fetchDentitionControlData,
            sexControlData,
            fetchSexControlData,
            weightRangeData,
            fetchWeightRangeControlData,

            filter,
            setFilter
        }} >
            {children}
        </DashboardContext.Provider>
    )
}

export function useDashboard() {
    const context = useContext(DashboardContext);

    if (!context) {
        throw new Error('useDashboard must be used within an DashboardProvider');
    }

    return context
}