import React, { FC, createContext, useState, useContext, useRef } from "react";
import moment from "moment";

import { theme } from "../components";
import { fetchFormsGroupSides, uploadFormsInference } from "../services/forms";

import {
    IForms,
    IFormsContext,
    IFormsFilterParams,
    ICompany,
} from "../types";

const FormsContext = createContext<IFormsContext>(
    {} as IFormsContext,
);

export const FormsProvider: FC<any> = ({ children }) => {
    const [forms, setForms] = useState<IForms[]>(
        [],
    );
    const [paginate, setPaginate] = useState<number>(0);
    const [perRow, setPerRow] = useState<string>("15");
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFormsFilterParams>({
        initialDate: moment().toDate(),
        finalDate: moment().toDate(),
        unitId: null,
    });


    const [documentWithError, setDocumentWithError] = useState<string[]>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const inputFile = useRef<HTMLInputElement>(null);
    const [progress, setProgress] = useState(0);
    const [loadingInf, setLoadingInf] = useState(false);
    const [countProcessingFile, setCountProcessingFile] = useState<number>(0);
    const [status, setStatus] = useState<string | null>(
        null,
    )
    const [fileNameProcessing, setFileNameProcessing] = useState<string | null>(
        null,
    );

    const fetchForms = async (
        _filter: IFormsFilterParams,
    ) => {
        setLoading(true);
        setForms([]);

        const forms: any[] =
            await fetchFormsGroupSides(_filter);

        setForms(forms);

        setFilter(_filter);

        setForms([...forms]);

        setLoading(false);
    };

    const uploadInference = async (
        _resultLambda: any,
    ) => {
        setLoading(true);
        setForms([]);


        await uploadFormsInference(_resultLambda);
    };

    return (
        <FormsContext.Provider
            value={{
                forms,
                loading,
                paginate,
                perRow,
                setPerRow,
                setPaginate,
                fetchForms,
                uploadInference,
                filter,
                setFilter,
                progress,
                setProgress,
                loadingInf,
                setLoadingInf,
                inputFile,
                countProcessingFile,
                setCountProcessingFile,
                status,
                setStatus,
                fileNameProcessing,
                setFileNameProcessing,
                documentWithError,
                setDocumentWithError,
                showModal,
                setShowModal,
            }}
        >
            {children}
        </FormsContext.Provider>
    );
};

export function useForms() {
    const context = useContext(FormsContext);

    if (!context) {
        throw new Error(
            "useForms must be used within an useFormsContextProvider",
        );
    }

    return context;
}
