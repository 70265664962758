import React, { FC, createContext, useContext, useState } from 'react'

import { createManagement, updateManagement, getManagements, destroyManagement, getManagement } from '../services/managements';
import { IManagement, IManagementContext } from '../types'

const ManagementsContext = createContext<IManagementContext>({} as IManagementContext);

export const ManagementsProvider: FC<any> = ({ children }) => {
    const [managements, setManagements] = useState<IManagement[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchManagement, setSearchManagement] = useState<string>('');
    const [managementsPerRow, setManagementsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchManagements = async () => {
        setLoading(true);
        try {
            const managements: IManagement[] = await getManagements();
            setManagements([...managements]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchManagement = async (_id: string) => {
        const management: IManagement = await getManagement(_id);
        return management;
    }

    const createNewManagement = async (_management: IManagement, _reload: boolean = true) => {
        try {
            const management = await createManagement(_management);
            if (_reload)
                fetchManagements();
            return management;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const editManagement = async (_id: string, _payload: IManagement) => {
        try {
            const management = await updateManagement(_id, _payload);

            fetchManagements();
            return management;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteManagement = async (_id: string) => {
        const management = await destroyManagement(_id);
        fetchManagements();
        return management;
    }

    return (
        <ManagementsContext.Provider value={{
            managements, loading, fetchManagements, fetchManagement,
            createNewManagement, editManagement, deleteManagement,
            searchManagement, setSearchManagement, managementsPerRow, setManagementsPerRow, paginate, setPaginate
        }}>{children}</ManagementsContext.Provider>
    )
}

export function useManagements() {
    const context = useContext(ManagementsContext);

    if (!context) {
        throw new Error('useManagements must be used within an ManagementsProvider')
    }

    return context;
}
