import React, { FC } from "react";
import ptBR from "rsuite/locales/pt_BR";

import { DateRangePicker as DataRangePickerComponent } from "rsuite";
import { IDateRangePicker } from "../../../types";
import { INITIAL_DATE, FINAL_DATE } from "../../../utils/consts";

import "./classes.css";

const DateRangePicker: FC<any> = (params: IDateRangePicker) => {
  const { allowedMaxDays } = DataRangePickerComponent;

  function setDates(arrayDate: any) {
    params.setInitialDate(arrayDate[INITIAL_DATE]);
    params.setFinalDate(arrayDate[FINAL_DATE]);
  }

  function clear() {
    params.setInitialDate(null);
    params.setFinalDate(null);
  }

  const styleMenu = { display: "block", zIndex: 15 };

  return (
    <DataRangePickerComponent
      placeholder={params.placeholder}
      menuStyle={styleMenu}
      cleanable={false}
      disabledDate={allowedMaxDays?.(params.maxDaysSelect)}
      defaultValue={[new Date(), new Date()]}
      block
      appearance="subtle"
      locale={ptBR.DateRangePicker}
      size="md"
      onOk={(newValue: any) => setDates(newValue)}
      disabled={params.disabled}
      onClean={() => clear()}
      onChange={params.onChange}
    />
  );
};

export default DateRangePicker;
