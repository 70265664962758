const colors = {
    primary: "#2a5caa",
    primaryLight: "#2a70aa",
    primaryDark: "#1c4a70",

    secondary: "#FFFFFF",
    tertiary: "#6AB0B7",

    textColor: "#140204",
    background: "#F6F6F6",
    backgroundLighter: "#FFF",

    error: "#FF482F",
    success: "#27871D",

    gray: "#C4C4C4",
    grayLight: "#E3E3E3",
    grayLighter: "#E9E9E9",
    grayDark: "#696969",
};

export { colors };
