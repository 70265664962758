import React, { FC } from 'react';

import { IUnitSelect } from '../../../../types';
import { useUnits } from '../../../../hooks/units';

import AutocompleteNew from '../../../atoms/autocomplete/new';
import Container from '../../../atoms/container';
import theme from '../../../theme';

const UnitSelect: FC<IUnitSelect> = (params: IUnitSelect) => {
    const { units } = useUnits();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <AutocompleteNew
                id="units"                
                required
                value={params.value}
                setValue={params.setValue}
                inputValue={params.inputValue}
                setInputValue={params.setInputValue}
                variant={params.variant || 'standard'}
                options={units as any[]}
                placeHolder={params.placeholder || 'Escolha a unidade'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists />
        </Container>
    );
}

export default UnitSelect;