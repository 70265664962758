import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/auth";
import { login as loginService } from "../../services/auth";
import { IUser } from "../../types";

import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../utils/consts";
import { colors } from "../../components/theme/styles";

import logo from "../../assets/pix_logo.png";
import backgroundImage from "../../assets/fundo.png";

import {
    Container,
    Image,
    Input,
    Page,
    Spacing,
    Text,
    theme,
} from "../../components";
import { color } from "@mui/system";

const Sign: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { login } = useAuth();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
        null,
    );

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value);
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const onLogin = async () => {
        setLoading(true);
        setLoginErrorMessage(null);

        setTimeout(async () => {
            try {
                // const res = await loginService(email, password);
                // if (res && res.token) {
                const user: IUser = {
                    id: "1",
                    email: "pabloteari@gmail.com",
                    name: "Pablo",
                    status: 1,
                    phone: "on",
                    avatar: "on",
                    annotatorId: "on",
                    privilege: "super user",
                    createdAt: "on",
                    updatedAt: "on",
                };
                login(user, "res.token");
                // }

                setLoading(false);
            } catch (_err) {
                if (_err instanceof Error) setLoginErrorMessage(_err.message);
                else setLoginErrorMessage("Ocorreu um erro ao efetuar o login");

                setLoading(false);
            }
        }, 1000);
    };

    return (
        <Page
            style={{
                backgroundImage: `url(${backgroundImage})`,
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
            color={colors.primary}
        >
            <Container fluid centered height="100vh">
                <Container
                    fluid
                    spacedEvenly
                    hasShadow
                    width="55%"
                    height="75%"
                    color={theme.palette.background.paper}
                    borderRadius={BORDER_RADIUS}
                    sx={{
                        maxWidth: "620px",
                        maxHeight: "650px",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                    }}
                >
                    <Container fluid centered width="100%">
                        <Spacing top={VERY_PADDING + 20} />
                        <Text semiBold size={27} color={colors.grayDark}>
                            Cadastrar novo usuário
                        </Text>
                    </Container>
                    <Spacing top={VERY_PADDING} />
                    <form onSubmit={handleSubmit(onLogin)}>
                        <Container fluid centered veryPadded>
                            <Container width="70%">
                                <Container fluid>
                                    <Input
                                        id="name"
                                        placeholder="Nome completo"
                                        size="medium"
                                        variant="outlined"
                                        value={email}
                                        error={errors.email != undefined}
                                        register={{
                                            ...register("email", {
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => handleChangeEmail(event),
                                                required: "Informe seu e-mail",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message:
                                                        "Informe um endereço de e-mail válido",
                                                },
                                            }),
                                        }}
                                        sx={{ height: 40 }}
                                    />
                                    {errors.email && (
                                        <Text
                                            size={13}
                                            color={theme.palette.error.main}
                                            style={{ marginBottom: 3 }}
                                        >
                                            {(errors.email as any).message}
                                        </Text>
                                    )}
                                </Container>

                                <Spacing top={25} />
                                <Container fluid>
                                    <Input
                                        id="email"
                                        placeholder="E-mail"
                                        size="medium"
                                        variant="outlined"
                                        value={email}
                                        error={errors.email != undefined}
                                        register={{
                                            ...register("email", {
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => handleChangeEmail(event),
                                                required: "Informe seu e-mail",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message:
                                                        "Informe um endereço de e-mail válido",
                                                },
                                            }),
                                        }}
                                        sx={{ height: 40 }}
                                    />
                                    {errors.email && (
                                        <Text
                                            size={13}
                                            color={theme.palette.error.main}
                                            style={{ marginBottom: 3 }}
                                        >
                                            {(errors.email as any).message}
                                        </Text>
                                    )}
                                </Container>

                                <Spacing top={25} />
                                <Container fluid>
                                    <Input
                                        id="company"
                                        placeholder="Empresa"
                                        size="medium"
                                        variant="outlined"
                                        value={email}
                                        error={errors.email != undefined}
                                        register={{
                                            ...register("email", {
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => handleChangeEmail(event),
                                                required: "Informe seu e-mail",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message:
                                                        "Informe um endereço de e-mail válido",
                                                },
                                            }),
                                        }}
                                        sx={{ height: 40 }}
                                    />
                                    {errors.email && (
                                        <Text
                                            size={13}
                                            color={theme.palette.error.main}
                                            style={{ marginBottom: 3 }}
                                        >
                                            {(errors.email as any).message}
                                        </Text>
                                    )}
                                </Container>
                                <Spacing top={25} />
                                <Container fluid>
                                    <Input
                                        id="address"
                                        placeholder="Endereço"
                                        size="medium"
                                        variant="outlined"
                                        value={email}
                                        error={errors.email != undefined}
                                        register={{
                                            ...register("email", {
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => handleChangeEmail(event),
                                                required: "Informe seu e-mail",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message:
                                                        "Informe um endereço de e-mail válido",
                                                },
                                            }),
                                        }}
                                        sx={{ height: 40 }}
                                    />
                                    {errors.email && (
                                        <Text
                                            size={13}
                                            color={theme.palette.error.main}
                                            style={{ marginBottom: 3 }}
                                        >
                                            {(errors.email as any).message}
                                        </Text>
                                    )}
                                </Container>

                                <Spacing top={25} />
                                <Container fluid>
                                    <Input
                                        id="password"
                                        placeholder="Senha"
                                        size="medium"
                                        variant="outlined"
                                        value={email}
                                        error={errors.email != undefined}
                                        register={{
                                            ...register("email", {
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => handleChangeEmail(event),
                                                required: "Informe seu e-mail",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message:
                                                        "Informe um endereço de e-mail válido",
                                                },
                                            }),
                                        }}
                                        sx={{ height: 40 }}
                                    />
                                    {errors.email && (
                                        <Text
                                            size={13}
                                            color={theme.palette.error.main}
                                            style={{ marginBottom: 3 }}
                                        >
                                            {(errors.email as any).message}
                                        </Text>
                                    )}
                                </Container>

                                <Spacing top={25} />

                                <Spacing top={VERY_PADDING} />

                                <Container fluid width="100%" centered>
                                    <Text
                                        size={13}
                                        color={theme.palette.error.main}
                                        style={{ marginBottom: 3 }}
                                    >
                                        {loginErrorMessage || ""}
                                    </Text>
                                </Container>
                            </Container>

                            <Container fluid width="70%">
                                <Spacing top={40} />
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    loading={loading}
                                    disabled={loading}
                                    style={{
                                        width: "100%",
                                        height: "50px",
                                        backgroundColor: loading
                                            ? theme.palette.grayLight.main
                                            : theme.palette.primary.dark,
                                    }}
                                >
                                    <Text
                                        color={theme.palette.background.default}
                                    >
                                        Finalizar Cadastro
                                    </Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </form>
                    <Spacing top={VERY_PADDING} />
                </Container>
            </Container>
        </Page>
    );
};

export default Sign;
