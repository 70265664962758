import { AxiosResponse } from "axios";

import api from ".";
import { IUnit, IUser } from "../types";
import { convertApiResponseToIUnit } from "./units";

const baseUrl: string = "users";

export const createUser = async (_user: IUser) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    email: _user.email,
    password: _user.password,
    name: _user.name,
    firstName: _user.firstName,
    lastName: _user.lastName,
    status: _user.status,
    phone: _user.phone,
    unitId: _user.unitId
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const user: IUser = convertApiResponseToIUser(response.data);
      return user;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um usuário");
};

export const updateUser = async (_id: string, _user: IUser) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    email: _user.email,
    name: _user.name,
    firstName: _user.firstName,
    lastName: _user.lastName,
    password: _user.password == "" ? undefined : _user.password,
    status: _user.status,
    unitId: _user.unitId
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const user: IUser = convertApiResponseToIUser(response.data);
      return user;
    }
  }

  throw new Error("Não foi possível editar um usuário");
};

export const updateUserAvatar = async (_id: string, _base64: string) => {
  const response: AxiosResponse = await api.put(
    `/v1_0/users/${_id}/avatar_uri`,
    {
      avatar_uri: _base64,
    }
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const user: IUser = convertApiResponseToIUser(response.data);
      return user;
    }
  }

  throw new Error("Não foi possível atualizar a foto do usuário");
};

export const destroyUser = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um usuário");
};

export const getUsers = async () => {
  const response: AxiosResponse = await api.get("/v1_0/users");

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const users: IUser[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const user: IUser = convertApiResponseToIUser(data);
        users.push(user);
      }

      return users;
    }
  }

  throw new Error("Não foi possível buscar os usuários");
};

export const getUser = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/users/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const user: IUser = convertApiResponseToIUser(response.data);
      return user;
    }
  }

  throw new Error("Não foi possível buscar os usuários");
};

export const convertApiResponseToIUser = (data: any) => {
  let privilege: any | undefined = undefined;
  if (data.privilege)
    privilege = {
      id: data.privilege.id,
      name: data.privilege.name,
      description: data.privilege.description,
    };
  let profile: any | undefined = undefined;
  if (data.profile) profile = data.profile;

  let unit: IUnit | undefined = undefined;
  if (data.unit) unit = convertApiResponseToIUnit(data.unit);

  const user: IUser = {
    id: data.id,
    email: data.email,
    name: profile ? profile.surname : "",
    status: data.status,
    phone: data.phone,
    avatar: data.avatar,
    annotatorId: data.annotatorId,
    privilege: privilege,
    profile,
    unitId: data.unitId,
    unit,
    unitName: unit ? unit.name : undefined,
    managementName: unit && unit.management ? unit.management.name : undefined,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };

  return user;
};
