import React, { FC, createContext, useContext, useState } from "react";

import {
    createCompany,
    updateCompany,
    getCompanys,
    destroyCompany,
    getCompany,
} from "../services/companies";
import { ICompany, ICompanyContext } from "../types";

const CompaniesContext = createContext<ICompanyContext>({} as ICompanyContext);

export const CompaniesProvider: FC<any> = ({ children }) => {
    const [companies, setCompanys] = useState<ICompany[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchCompany, setSearchCompany] = useState<string>("");
    const [companiesPerRow, setCompaniesPerRow] = useState<string>("25");
    const [paginate, setPaginate] = useState<number>(0);

    const fetchCompanies = async () => {
        setLoading(true);
        try {
            const companies: ICompany[] = await getCompanys();
            setCompanys([...companies]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchCompany = async (_id: string) => {
        const company: ICompany = await getCompany(_id);
        return company;
    };

    const createNewCompany = async (
        _company: ICompany,
        _reload: boolean = true,
    ) => {
        const company = await createCompany(_company);
        if (_reload) fetchCompanies();
        return company;
    };

    const editCompany = async (_id: string, _payload: ICompany) => {
        try {
            const company = await updateCompany(_id, _payload);

            fetchCompanies();
            return company;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    };

    const deleteCompany = async (_id: string) => {
        const company = await destroyCompany(_id);
        fetchCompanies();
        return company;
    };

    return (
        <CompaniesContext.Provider
            value={{
                companies,
                loading,
                fetchCompanies,
                fetchCompany,
                createNewCompany,
                editCompany,
                deleteCompany,
                searchCompany,
                setSearchCompany,
                companiesPerRow,
                setCompaniesPerRow,
                paginate,
                setPaginate,
            }}
        >
            {children}
        </CompaniesContext.Provider>
    );
};

export function useCompanies() {
    const context = useContext(CompaniesContext);

    if (!context) {
        throw new Error(
            "useCompanies must be used within an CompaniesProvider",
        );
    }

    return context;
}
