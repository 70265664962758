import { AxiosResponse } from "axios";

import api from ".";
import { IManagement } from "../types";

const baseUrl: string = "managements";

export const createManagement = async (_management: IManagement) => {
  const response: AxiosResponse = await api.post(`/v1_0/${baseUrl}`, {
    code: _management.code,
    name: _management.name,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIManagement(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if ((response as any).response.data.error)
        throw new Error((response as any).response.data.error);
      else throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateManagement = async (
  _id: string,
  _management: IManagement
) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    code: _management.code,
    name: _management.name,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIManagement(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if ((response as any).response.data.error)
        throw new Error((response as any).response.data.error);
      else throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyManagement = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getManagements = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const managements: IManagement[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const management: IManagement = convertApiResponseToIManagement(data);

        managements.push(management);
      }

      return managements;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getManagement = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const management: IManagement = convertApiResponseToIManagement(
        response.data
      );
      return management;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIManagement = (_management: any) => {
  const management: IManagement = {
    id: _management.id,
    code: _management.code,
    name: _management.name,
    isDeleted: _management.isDeleted,
    createdAt: _management.createdAt,
    updatedAt: _management.updatedAt,
  };

  return management;
};
