import React from "react";
import { Routes, Route } from "react-router-dom";

import { AppHeader } from "../components";
import HooksProvider from "../hooks";
import { useAuth } from "../hooks/auth";
import {
    AnalysisAnnotations,
    Companies,
    CompanyRegister,
    CompaniesHasUsers,
    CompanyHasUserRegister,
    Dashboard,
    Home,
    Forms,
    Login,
    Managements,
    Privileges,
    PrivilegeRegister,
    Routes as RoutesPage,
    RouteRegister,
    Sign,
    Units,
    Upload,
    Users,
    UserRegister,
    ManagementsRegister,
    UnitsRegister,
} from "../pages";
import UsersRegister from "../pages/users/register";

const AuthRoutes: React.FC = () => {
    const { hasRights, user } = useAuth();

    return (
        <HooksProvider>
            <AppHeader />

            <Routes>
                <Route path="/" element={<Home />} />
                {hasRights('/forms') && <Route path="/forms" element={<Forms />} />}
                {hasRights('/login') && <Route path="/login" element={<Login />} />}
                {hasRights('/managements') && <Route path="/managements" element={<Managements />} />}
                {hasRights('/managements/register') && <Route path="/managements/register" element={<ManagementsRegister />} />}
                {hasRights('/managements/:id') && <Route path="/managements/:id" element={<ManagementsRegister />} />}
                {hasRights('/sign') && <Route path="/sign" element={<Sign />} />}
                {hasRights('/units') && <Route path="/units" element={<Units />} />}
                {hasRights('/units/register') && <Route path="/units/register" element={<UnitsRegister />} />}
                {hasRights('/units/:id') && <Route path="/units/:id" element={<UnitsRegister />} />}
                {hasRights('/upload') && <Route path="/upload" element={<Upload />} />}
                {hasRights('/users') && <Route path="/users" element={<Users />} />}
                {hasRights('/users/register') && <Route path="/users/register" element={<UsersRegister />} />}
                {hasRights('/users/:id') && <Route path="/users/:id" element={<UsersRegister />} />}
                <Route path={`/users/${user?.id}`} element={<UsersRegister id={user?.id} />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </HooksProvider>
    );
};

export default AuthRoutes;
