import React from 'react';

import TextStyled from './styled';
import { IText } from '../../../types';

const Text: React.FC<IText> = (params: IText) => {
    return (
        <TextStyled
            ref={params.ref}
            className={params.className}
            center={params.center}
            size={params.size || 16}
            thin={params.thin}
            extraLight={params.extraLight}
            light={params.light}
            medium={params.medium}
            semiBold={params.semiBold}
            bold={params.bold}
            extraBold={params.extraBold}
            black={params.black}
            color={params.color}
            style={{ ...params.style }}
            onClick={params.onClick}>
            {params.children}
        </TextStyled>
    );
};

export default Text;
