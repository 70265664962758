import React, { FC, createContext, useContext, useState } from 'react'

import { createUser, updateUser, getUsers, destroyUser, getUser } from '../services/users';
import { createUserHasPrivilege } from '../services/usersHasPrivileges';
import { IUser, IUserContext } from '../types'

const UsersContext = createContext<IUserContext>({} as IUserContext);

export const UsersProvider: FC<any> = ({ children }) => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchUser, setSearchUser] = useState<string>('');
    const [usersPerRow, setUsersPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);
    
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const users: IUser[] = await getUsers();
            setUsers([...users]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchUser = async (_id: string) => {
        const user: IUser = await getUser(_id);
        return user;
    }

    const createNewUser = async (payload: IUser, reload: boolean = true) => {
        const user = await createUser(payload);

        if (reload)
            fetchUsers();
        return user;
    }

    const editUser = async (_id: string, _payload: IUser) => {
        try {
            const user = await updateUser(_id, _payload);

            fetchUsers();
            return user;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteUser = async (_id: string) => {
        const user = await destroyUser(_id);
        fetchUsers();
        return user;
    }

    const createLinkWithPrivilege = async (_userId: string, _privilegeId: string) => {
        const id: string = await createUserHasPrivilege(_userId, _privilegeId);
        return '';
    };

    return (
        <UsersContext.Provider value={{
            users, loading, fetchUsers, fetchUser,
            createNewUser, editUser, deleteUser,
            createLinkWithPrivilege,
            searchUser, setSearchUser, usersPerRow, setUsersPerRow, paginate, setPaginate
        }}>{children}</UsersContext.Provider>
    )
}

export function useUsers() {
    const context = useContext(UsersContext);

    if (!context) {
        throw new Error('useusers must be used within an UsersProvider')
    }

    return context;
}