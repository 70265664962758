import { AxiosResponse } from "axios";

import api from ".";
import { ICompany, ICompanyHasUser, IUser } from "../types";
import { convertApiResponseToICompany } from "./companies";
import { convertApiResponseToIUser } from "./users";

const baseUrl: string = "companies_has_users";

export const createCompanyHasUser = async (
  _companyHasUser: ICompanyHasUser
) => {
  const response: AxiosResponse = await api.post(
    `/v1_0/${baseUrl}`,
    _companyHasUser
  );
  
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToICompanyHasUser(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      if ((response as any).response.data.error)
        throw new Error((response as any).response.data.error as string);
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateCompanyHasUser = async (
  _id: string,
  _companyHasUser: ICompanyHasUser
) => {
  const response: AxiosResponse = await api.put(`/v1_0/${baseUrl}/${_id}`, {
    companyId: _companyHasUser.companyId,
    userId: _companyHasUser.userId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToICompanyHasUser(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyCompanyHasUser = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getCompaniesHasUser = async () => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const companiesHasUser: ICompanyHasUser[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const companyHasUser: ICompanyHasUser =
          convertApiResponseToICompanyHasUser(data);

        companiesHasUser.push(companyHasUser);
      }

      return companiesHasUser;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getCompanyHasUser = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const companyHasUser: ICompanyHasUser =
        convertApiResponseToICompanyHasUser(response.data);
      return companyHasUser;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToICompanyHasUser = (_companyHasUser: any) => {
  let company: ICompany | undefined = undefined;
  if (_companyHasUser.company)
    company = convertApiResponseToICompany(_companyHasUser.company);
  let user: IUser | undefined = undefined;
  if (_companyHasUser.user)
    user = convertApiResponseToIUser(_companyHasUser.user);

  const companyHasUser: ICompanyHasUser = {
    id: _companyHasUser.id,
    companyId: _companyHasUser.companyId,
    company,
    userId: _companyHasUser.userId,
    user,
    isDeleted: _companyHasUser.isDeleted,
    createdAt: _companyHasUser.createdAt,
    updatedAt: _companyHasUser.updatedAt,
  };

  return companyHasUser;
};
