import React, { FC, createContext, useContext, useState } from 'react'

import { createCompanyHasUser, updateCompanyHasUser, getCompaniesHasUser, destroyCompanyHasUser, getCompanyHasUser } from '../services/companiesHasUser';
import { ICompanyHasUser, ICompanyHasUserContext } from '../types'

const CompaniesHasUserContext = createContext<ICompanyHasUserContext>({} as ICompanyHasUserContext);

export const CompaniesHasUserProvider: FC<any> = ({ children }) => {
    const [companiesHasUser, setCompaniesHasUser] = useState<ICompanyHasUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchCompanyHasUser, setSearchCompanyHasUser] = useState<string>('');
    const [companiesHasUserPerRow, setCompaniesHasUserPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchCompaniesHasUser = async () => {
        setLoading(true);
        try {
            const companiesHasUser: ICompanyHasUser[] = await getCompaniesHasUser();
            setCompaniesHasUser([...companiesHasUser]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchCompanyHasUser = async (_id: string) => {
        const companyHasUser: ICompanyHasUser = await getCompanyHasUser(_id);
        return companyHasUser;
    }

    const createNewCompanyHasUser = async (_companyHasUser: ICompanyHasUser, _reload: boolean = true) => {
        const companyHasUser = await createCompanyHasUser(_companyHasUser);
        if (_reload)
            fetchCompaniesHasUser();
        return companyHasUser;
    }

    const editCompanyHasUser = async (_id: string, _payload: ICompanyHasUser) => {
        try {
            const companyHasUser = await updateCompanyHasUser(_id, _payload);

            fetchCompaniesHasUser();
            return companyHasUser;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteCompanyHasUser = async (_id: string) => {
        const companyHasUser = await destroyCompanyHasUser(_id);
        fetchCompaniesHasUser();
        return companyHasUser;
    }

    return (
        <CompaniesHasUserContext.Provider value={{
            companiesHasUser, loading, fetchCompaniesHasUser, fetchCompanyHasUser,
            createNewCompanyHasUser, editCompanyHasUser, deleteCompanyHasUser,
            searchCompanyHasUser, setSearchCompanyHasUser, companiesHasUserPerRow, setCompaniesHasUserPerRow, paginate, setPaginate
        }}>{children}</CompaniesHasUserContext.Provider>
    )
}

export function useCompaniesHasUser() {
    const context = useContext(CompaniesHasUserContext);

    if (!context) {
        throw new Error('useCompaniesHasUser must be used within an CompaniesHasUserProvider')
    }

    return context;
}
